import React from 'react';
import Form from '../../core/input/Form/Form';
import AssignmentDiagram from '../AssignmentDiagram';
import { Assignment } from '../../../types/types';

interface Props {
  peerEvaluationOnly: boolean;
  groupsEnabled: boolean;
  instructorUpload: boolean;
  peerEvaluationEnabled: boolean;
  instructorGradedOnly: boolean;
  feedbackEnabled: boolean;
  reflectionEnabled: boolean;
}

function VisualConfirmSetup({
  peerEvaluationOnly,
  groupsEnabled,
  instructorUpload,
  peerEvaluationEnabled,
  instructorGradedOnly,
  feedbackEnabled,
  reflectionEnabled
}: Props): JSX.Element {
  return (
    <>
      <Form.Header>
        <Form.Title>Confirm & Continue</Form.Title>
      </Form.Header>
      <Form.Body>
        <p>Here&apos;s a visualization of your chosen core assignment settings:</p>
        <div className="settings-visual">
          <AssignmentDiagram
            assignment={
              {
                peerEvaluationOnly,
                groupsEnabled,
                instructorUpload,
                peerEvaluationEnabled,
                instructorGradedOnly,
                feedbackEnabled,
                reflectionEnabled
              } as Assignment
            }
          />
        </div>
      </Form.Body>
    </>
  );
}

export default VisualConfirmSetup;
