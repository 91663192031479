import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setReduxAssignment } from '../../../actions';
import { openModal, useModalContext } from '../../../contexts/ModalContext';
import { Assignment, Course, PhaseCode } from '../../../types/types';
import { getPhaseName } from '../../../utils/functions';
import { endPhase } from '../../../utils/requests';
import Button from '../../core/button/Button/Button';
import AssignmentCalendar from '../../core/display/AssignmentCalendar';
import AssignmentPhaseIcon from '../../core/display/AssignmentPhaseIcon/AssignmentPhaseIcon';
import Icon from '../../core/display/Icon';
import PhaseNowCard from '../PhaseNowCard';

interface Props {
  assignment: Assignment;
  course: Course;
  timeRemaining: string;
}

function AssignmentTimelineCard({ assignment, course, timeRemaining }: Props): JSX.Element {
  const dispatch = useDispatch();
  const { modalDispatch } = useModalContext();

  const {
    submissionDeadline,
    reviewDeadline,
    feedbackDeadline,
    peerEvaluationDeadline,
    reflectionDeadline,
    asyncEndDeadline,
    assignmentId,
  } = assignment;
  const { submissionPhase, reviewPhase, feedbackPhase, evalPhase, reflectionPhase } = assignment.progressStats ?? {};

  return (
    <div id="timeline-card" className="card">
      <PhaseNowCard assignment={assignment} timeRemaining={timeRemaining}>
        <Button
          id="end-phase-btn"
          variant="alt rad low xs"
          onClick={() => {
            modalDispatch(
              openModal({
                heading: 'End Phase',
                label: 'Are you sure you want end the current phase early?',
                buttonText: 'End',
                onConfirm: () => {
                  if (assignmentId) endPhase(assignmentId, (data) => dispatch(setReduxAssignment(data)));
                },
              }),
            );
          }}
        >
          End Phase
        </Button>
      </PhaseNowCard>

      <div className="heading-wrapper">
        <h2>Timeline</h2>
        <p id="deadline-time">
          All Deadlines @ {moment(assignment.deadlineTime, 'hh:mm:ss').format('h:mm A')}{' '}
          {moment().tz(course.timeZone).format('z')}
        </p>
        <Button
          id="calendar-btn"
          className="button-mini"
          classOverride
          onClick={() =>
            modalDispatch(
              openModal({
                heading: 'Assignment Calendar',
                padding: '1rem',
                closeButton: true,
                noActionButtons: true,
                children: <AssignmentCalendar assignment={assignment} timezone={course.timeZone} />,
              }),
            )
          }
        >
          <Icon code="date_range" label="Open Calendar" />
        </Button>
      </div>
      {submissionDeadline ? (
        <TimelineEntry phase="submit" date={submissionDeadline} current={submissionPhase} timezone={course.timeZone} />
      ) : null}
      {reviewDeadline ? (
        <TimelineEntry phase="review" date={reviewDeadline} current={reviewPhase} timezone={course.timeZone} />
      ) : null}
      {feedbackDeadline ? (
        <TimelineEntry phase="feedback" date={feedbackDeadline} current={feedbackPhase} timezone={course.timeZone} />
      ) : null}
      {peerEvaluationDeadline ? (
        <TimelineEntry phase="evaluate" date={peerEvaluationDeadline} current={evalPhase} timezone={course.timeZone} />
      ) : null}
      {reflectionDeadline ? (
        <TimelineEntry phase="reflection" date={reflectionDeadline} current={reflectionPhase} timezone={course.timeZone} />
      ) : null}
      {asyncEndDeadline ? (
        <TimelineEntry phase="async" date={asyncEndDeadline} current={true} timezone={course.timeZone} />
      ) : null}
    </div>
  );
}

interface TimelineEntryProps {
  current?: boolean;
  date: string;
  phase: PhaseCode;
  timezone: string;
}

function TimelineEntry({ current, date, phase, timezone }: TimelineEntryProps): JSX.Element {
  const dateMoment = moment(date).tz(timezone);
  const past = moment().isAfter(dateMoment);

  return (
    <div className={`timeline-entry ${past ? 'past' : 'upcoming'}${current ? ' current' : ''}`}>
      <span className="date">
        <div className="weekday">{dateMoment.format('ddd')}</div>
        <div className="day">{dateMoment.format('DD')}</div>
      </span>
      <AssignmentPhaseIcon phase={phase} size={24} />
      <h3>{getPhaseName(phase)} Deadline</h3>
    </div>
  );
}

export default AssignmentTimelineCard;
