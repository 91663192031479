import React, { ReactChild, useCallback, useEffect, useState } from 'react';
import { VARIANT_LARGE, VARIANT_SMALL } from '../../../../utils/constants';
import RichReader from '../../display/RichReader';

const moveTransitionElement = (targetElem: HTMLElement, id: string) => {
  if (targetElem) {
    const x2 = targetElem.offsetLeft;

    const transitionElem = document.getElementById(`select-transition-${id}`);
    if (transitionElem) {
      transitionElem.style.opacity = '1';
      transitionElem.style.height = '0.2em';
      transitionElem.style.margin = '1.4em 0 1.4em 0';
      setTimeout(() => {
        transitionElem.style.height = '3em';
        transitionElem.style.margin = '0';
      }, 100);

      const x1 = transitionElem.offsetLeft;

      const dx = x2 - x1;
      const dy = 0;
      transitionElem.style.transform = `translate(${dx}px,${dy}px)`;
    }
  }
};

interface RatingProps {
  children: React.ReactNode;
  id: string;
  legend: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  requireRubricConfirmation?: boolean;
  richLegend?: boolean;
  variant?: 'lg' | 'sm';
}

function Rating({ children, id, legend, onChange, richLegend = false, variant }: RatingProps): JSX.Element {
  const [sortedChildren, setSortedChildren] = useState<ReactChild[]>([]);

  /**
   * The event handler for changes to rating entries
   * @param {object} e Event
   */
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(e);

      // Move transition element
      if (variant === VARIANT_SMALL) {
        moveTransitionElement(e.target, id);
      }
    },
    [variant, onChange, id],
  );

  const updateLabelSupplement = useCallback(
    (input: HTMLElement) => {
      // Get all labels
      const labels = document.getElementsByTagName('LABEL');
      for (let i = 0; i < labels.length; i++) {
        const currLabel = labels[i] as HTMLLabelElement;
        if (currLabel.htmlFor === input.id) {
          // Set text to label's text
          const destElem = document.getElementById(`rating-label-supplement-${id}`);
          if (destElem) {
            destElem.innerHTML = labels[i].innerHTML;
            requestAnimationFrame(() => {
              destElem.style.opacity = '1';
              destElem.classList.remove('animate__animated');
              destElem.classList.remove('animate__fadeOut');
            });
          }

          // Change element's ::before
          let beforeStyle = document.getElementById('label-supplement-style');
          // Create style element if it doesn't yet exist
          if (!beforeStyle) {
            beforeStyle = document.createElement('style');
            beforeStyle.setAttribute('id', 'label-supplement-style');
            document.head.appendChild(beforeStyle);
          }
          // Set to position of target element
          const position = input.offsetLeft + input.offsetWidth / 2 - 10;
          beforeStyle.innerHTML = `.rating-label-supplement-container-sm::before {left: ${position}px}`;
        }
      }
    },
    [id],
  );

  /**
   * The event handler for mousing over the rating card
   * @param {object} e Event
   */
  const handleFocusIn = useCallback(
    (e: React.FocusEvent | React.MouseEvent) => {
      if (variant === VARIANT_SMALL) {
        if ((e.target as HTMLElement).tagName === 'INPUT') updateLabelSupplement(e.target as HTMLElement);
      }
    },
    [variant, updateLabelSupplement],
  );

  /**
   * The event handler for mousing out of the rating card
   * @param {object} e Event
   */
  const handleFocusOut = useCallback(
    (e: React.FocusEvent | React.MouseEvent) => {
      const fadeOutLabelSupplement = () => {
        // Animate fade out
        const suppElem = document.getElementById(`rating-label-supplement-${id}`);
        if (suppElem)
          requestAnimationFrame(() => {
            suppElem.classList.add('animate__animated');
            suppElem.classList.add('animate__fadeOut');
          });
      };

      if (variant === VARIANT_SMALL) {
        if ((e.target as HTMLElement).tagName === 'INPUT') fadeOutLabelSupplement();
      }
    },
    [variant, id],
  );

  const renderLarge = useCallback(() => {
    return (
      <div className="rating-card-lg" id={`rating-card-${id}`} onChange={handleChange}>
        {sortedChildren}
      </div>
    );
  }, [sortedChildren, id, handleChange]);

  const renderSmall = useCallback(() => {
    return (
      <>
        <div
          className="rating-card-sm"
          id={`rating-card-${id}`}
          onChange={handleChange}
          onMouseOver={handleFocusIn}
          onMouseOut={handleFocusOut}
          onFocus={handleFocusIn}
          onBlur={handleFocusOut}
        >
          <div className="rating-card-select-transition-sm" id={`select-transition-${id}`} />
          {sortedChildren}
        </div>
        <div className="rating-label-supplement-container-sm" id={`rating-label-supplement-${id}`} />
      </>
    );
  }, [sortedChildren, id, handleChange, handleFocusIn, handleFocusOut]);

  const selectVariant = useCallback(() => {
    if (!variant) return renderLarge;
    switch (variant) {
      case VARIANT_LARGE:
        return renderLarge();
      case VARIANT_SMALL:
        return renderSmall();
      default:
        return renderLarge();
    }
  }, [variant, renderLarge, renderSmall]);

  useEffect(() => {
    if (children) {
      const childArray = React.Children.toArray(children) as ReactChild[];
      setSortedChildren(variant === 'lg' ? childArray : childArray.reverse());
    }
  }, [children, variant]);

  return (
    <fieldset id={`fieldset-${id}`}>
      <>
        <legend>{richLegend ? <RichReader content={legend} /> : <p>{legend}</p>}</legend>
        {selectVariant()}
      </>
    </fieldset>
  );
}

interface EntryProps {
  children: React.ReactNode;
  defaultChecked?: boolean;
  id: string;
  name: string;
  onInvalid?: () => void;
  value: number;
  variant?: 'lg' | 'sm';
}

function Entry({ children, defaultChecked, id, name, onInvalid, value, variant }: EntryProps): JSX.Element {
  const renderLarge = useCallback(() => {
    return (
      <div className="rating-entry-wrapper-lg">
        <input
          type="radio"
          id={`rating-level-${id}`}
          name={'rating-' + name}
          value={value}
          defaultChecked={defaultChecked}
          required={true}
          onInvalid={onInvalid}
        />
        <label className="rating-entry-lg" htmlFor={'rating-level-' + id}>
          {value} - {children}
        </label>
      </div>
    );
  }, [children, id, name, value, defaultChecked, onInvalid]);

  const renderSmall = useCallback(() => {
    return (
      <div className="rating-entry-wrapper-sm">
        <input
          type="radio"
          id={`rating-level-${id}`}
          name={'rating-' + name}
          value={value}
          defaultChecked={defaultChecked}
          required={true}
          onInvalid={onInvalid}
        />
        <label className="rating-entry-sm sr-only" htmlFor={'rating-level-' + id}>
          {children}
        </label>
        <span className="rating-entry-radio-btn-sm">{value}</span>
      </div>
    );
  }, [children, id, name, value, defaultChecked, onInvalid]);

  const selectVariant = useCallback(() => {
    if (!variant) return renderLarge();
    switch (variant) {
      case VARIANT_LARGE:
        return renderLarge();
      case VARIANT_SMALL:
        return renderSmall();
      default:
        return renderLarge();
    }
  }, [variant, renderLarge, renderSmall]);

  // Move transition element to this entry if it's the default checked button
  useEffect(() => {
    if (variant === VARIANT_SMALL && defaultChecked) {
      const targetElem = document.getElementById(`rating-level-${id}`);
      if (targetElem) moveTransitionElement(targetElem, name);
    }
  }, [defaultChecked, variant, id, name]);

  return selectVariant();
}

Rating.Entry = Entry;

export default Rating;
