import React from 'react';
import { useSelector } from 'react-redux';
import Button from '../core/button/Button/Button';
import { selectAssignment, selectUser } from '../../store/selectors';

interface Props {
  courseId: string;
  assignmentId: string;
  canSelfManageGroup: boolean;
  requestCreateGroup: (assignmentId: string, groupName: string) => void;
}

function StudentGroupsNoGroup({ courseId, assignmentId, canSelfManageGroup, requestCreateGroup }: Props): JSX.Element {
  const basePath = `/course/${courseId}/assignment/${assignmentId}/groups`;

  const user = useSelector(selectUser);
  const assignment = useSelector(selectAssignment);

  return (
    <div id="no-group">
      <h1>You Are Not Yet In A Group</h1>
      {canSelfManageGroup ? (
        <>
          <Button
            id="group-create-btn"
            variant="rad alt"
            onClick={() => {
              requestCreateGroup(assignmentId, `${assignment?.anonymousGroups ? 'Anonymous' : user.firstName}'s Group`);
            }}
          >
            Create a Group
          </Button>
          <p>O R</p>
          <Button variant="rad alt" href={`${basePath}/student-list`} route>
            Join a Group (via Student)
          </Button>
          <Button variant="rad alt" href={`${basePath}/group-list`} route>
            Join a Group (via Group)
          </Button>
        </>
      ) : (
        <p>Your instructor must add you to a group</p>
      )}
    </div>
  );
}

export default StudentGroupsNoGroup;
