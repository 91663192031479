import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment-timezone';
import { getTimezoneList, setPageTitle } from '../../utils/functions';
import SingleForm from '../core/input/Form/SingleForm';
import CourseCard from './CourseCard';
import HelpTag from '../core/display/HelpTag';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
const { Row, Col, FormGroup, InputGroup } = SingleForm;

interface Props {
  initCourseName?: string;
  initDiscipline?: string;
  initUniversity?: string;
  initTimeZone?: string;
  initLabel?: string;
  initStudentPurchaseEnabled?: 'true' | 'false';
  initAsyncEnabled?: 'true' | 'false';
  onSubmit?: (arg0: FormData, callback: () => void) => void;
  title: string;
  type: 'NEW' | 'EDIT' | 'COPY';
}

function CourseForm({
  initCourseName = '',
  initDiscipline = '',
  initUniversity = '',
  initTimeZone,
  initLabel = '',
  initStudentPurchaseEnabled = 'false',
  initAsyncEnabled = 'false',
  onSubmit = () => undefined,
  title,
  type,
}: Props): JSX.Element {
  useEffect(() => setPageTitle(`${type.charAt(0) + type.slice(1).toLowerCase()} Course`), [type]);

  const user = useSelector((state: RootState) => state.user);

  const date = new Date();
  const month = date.getMonth();
  const currYear = date.getUTCFullYear();

  let season: 'Spring' | 'Summer' | 'Fall' | 'Winter' | 'Full Year' = 'Spring';
  if (month >= 11 && month <= 1) season = 'Winter';
  else if (month >= 2 && month <= 4) season = 'Spring';
  else if (month >= 5 && month <= 7) season = 'Summer';
  else if (month >= 8 && month <= 10) season = 'Fall';

  const years: number[] = [];
  for (let i = currYear; i <= currYear + 3; i++) years.push(i);

  const [name, setName] = useState<string>(initCourseName);
  const [discipline, setDiscipline] = useState<string>(initDiscipline);
  const [institution, setInstitution] = useState<string>(initUniversity);
  const [semester, setSemester] = useState<'Spring' | 'Summer' | 'Fall' | 'Winter' | 'Full Year'>(season);
  const [year, setYear] = useState<string>(currYear + '');
  const [label, setLabel] = useState<string>(initLabel);
  const [studentPurchaseEnabled, setStudentPurchaseEnabled] = useState<'true' | 'false'>(initStudentPurchaseEnabled);
  const [asyncEnabled, setAsyncEnabled] = useState<'true' | 'false'>(initAsyncEnabled);

  const timezone = useRef<string>(initTimeZone ? initTimeZone : moment.tz.guess());
  const timezoneList = useRef<string[]>(getTimezoneList());

  return (
    <SingleForm
      className="course-form"
      title={title}
      submitText={type === 'EDIT' ? 'Save Changes' : 'Create'}
      onSubmit={onSubmit}
      backButton
      backUrl="/course/dashboard"
    >
      <Row>
        <Col>
          <FormGroup>
            <InputGroup iconCode="class" flexDirection="column">
              <label className="sr-only" htmlFor="course-name">
                Course Name
              </label>
              <input
                name="courseName"
                id="course-name"
                type="text"
                placeholder="Course Name"
                required={true}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <label className="sr-only" htmlFor="course-discipline">
                Discipline
              </label>
              <input
                name="discipline"
                id="course-discipline"
                type="text"
                placeholder="Discipline"
                required={true}
                value={discipline}
                onChange={(e) => {
                  setDiscipline(e.target.value);
                }}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup iconCode="place" flexDirection="column">
              <label className="sr-only" htmlFor="course-institution">
                Institution
              </label>
              <input
                name="university"
                id="course-institution"
                type="text"
                placeholder="Institution"
                required={true}
                value={institution}
                onChange={(e) => {
                  setInstitution(e.target.value);
                }}
              />
            </InputGroup>
            <InputGroup iconCode="event">
              <label className="sr-only" htmlFor="course-semester">
                Semester
              </label>
              <select
                name="semester"
                id="course-semester"
                required={true}
                value={semester}
                onChange={(e) => {
                  setSemester(e.target.value as 'Spring' | 'Summer' | 'Fall' | 'Winter' | 'Full Year');
                }}
              >
                <option>Spring</option>
                <option>Summer</option>
                <option>Fall</option>
                <option>Winter</option>
                <option>Full Year</option>
              </select>
              <label className="sr-only" htmlFor="course-year">
                Year
              </label>
              <select
                name="year"
                id="course-year"
                required={true}
                value={year}
                onChange={(e) => {
                  setYear(e.target.value);
                }}
              >
                {years.map((yr) => (
                  <option key={yr}>{yr}</option>
                ))}
              </select>
            </InputGroup>
            <InputGroup iconCode="language">
              <label className="sr-only" htmlFor="course-timezone">
                Timezone
              </label>
              <select name="timeZone" id="course-timezone" defaultValue={timezone.current} required={true}>
                {timezoneList.current.map((tz) => (
                  <option key={tz}>{tz}</option>
                ))}
              </select>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup iconCode="label">
              <label className="sr-only" htmlFor="course-label">
                Label (Optional)
              </label>
              <input
                name="label"
                id="course-label"
                type="text"
                placeholder="Label (Optional)"
                value={label}
                onChange={(e) => {
                  setLabel(e.target.value);
                }}
              />
              <HelpTag>
                <b>Labels</b> can be used to identify the course. <i>(i.e. section, schedule...)</i>
              </HelpTag>
            </InputGroup>
          </FormGroup>
          {user.purchasingEnabled ? (
            <FormGroup>
              <InputGroup iconCode="attach_money">
                <label className="sr-only" htmlFor="course-purchase">
                  Purchase Options
                </label>
                <select
                  name="studentPurchaseEnabled"
                  id="course-purchase"
                  required={true}
                  value={studentPurchaseEnabled}
                  onChange={(e) => {
                    setStudentPurchaseEnabled(e.target.value as 'true' | 'false');
                  }}
                  disabled={type === 'EDIT'}
                >
                  <option value="false">Enrollment Seats Purchased By Instructor</option>
                  <option value="true">Enrollment Seats Purchased By Students</option>
                </select>
              </InputGroup>
            </FormGroup>
          ) : null}

          <FormGroup>
            <InputGroup iconCode="schedule">
              <label className="sr-only" htmlFor="course-sync">
                Synchronization Options
              </label>
              <select
                name="asyncEnabled"
                id="course-sync"
                required={true}
                value={asyncEnabled}
                onChange={(e) => {
                  setAsyncEnabled(e.target.value as 'true' | 'false');
                }}
                disabled={type === 'EDIT'}
              >
                <option value="false">Sync Mode</option>
                <option value="true">Async Mode</option>
              </select>
              <HelpTag>
                <p>
                  <b>Sync Mode:</b> Multiple set deadlines and grading
                </p>
                <p>
                  <b>Async Mode:</b> Final deadline only and competency-based grading
                </p>
              </HelpTag>
            </InputGroup>
          </FormGroup>
        </Col>
        <Col className="justify-center">
          <figure className="course-card-preview">
            <CourseCard
              name={name === '' ? 'Course Name' : name}
              discipline={discipline === '' ? 'Discipline' : discipline}
              semester={semester}
              year={parseInt(year)}
              label={label === '' ? 'Label' : label}
              readOnly={true}
            />
            <figcaption>Preview</figcaption>
          </figure>
        </Col>
      </Row>
    </SingleForm>
  );
}

export default CourseForm;
