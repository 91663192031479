import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import MainNavbar from '../Navbar/MainNavbar';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import HomeBanner, { homePaths } from '../HomeBanner/HomeBanner';
import UserBanner from '../HomeBanner/UserBanner';
import { isHeightReflow } from '../../../../utils/functions';

const NAVBAR_HEIGHT = 56;

function SiteBanner(): JSX.Element {
  const user = useSelector((state: RootState) => state.user);

  const [heightBP, setHeightBP] = useState(isHeightReflow());

  useEffect(() => {
    const resizeHandler = () => setHeightBP(isHeightReflow());
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  });

  useEffect(() => {
    const contentSnapshot = document.getElementById('site-content');
    const scrollHandler = (e: Event) => {
      const scrollWrapper = e.target as HTMLElement;
      const siteBanner = document.getElementById('site-banner');
      const siteContent = document.getElementById('site-content');
      const navbar = document.getElementById('main-navbar');
      const isHidden = navbar?.getAttribute('scroll-hidden');
      if (heightBP && navbar && siteBanner && siteContent) {
        if (isHidden === 'true') {
          if (scrollWrapper.scrollTop < 1) {
            navbar.style.top = `0`;
            siteBanner.style.paddingTop = `${NAVBAR_HEIGHT}px`;
            navbar.setAttribute('scroll-hidden', 'false');
          }
        } else {
          if (scrollWrapper.scrollTop > 56) {
            navbar.style.top = `-${NAVBAR_HEIGHT}px`;
            siteBanner.style.paddingTop = `0`;
            navbar.setAttribute('scroll-hidden', 'true');
          }
        }
      }
    };
    contentSnapshot?.addEventListener('scroll', scrollHandler);
    return () => {
      contentSnapshot?.removeEventListener('scroll', scrollHandler);
    };
  }, [heightBP]);

  useEffect(() => {
    if (!heightBP) {
      const navbar = document.getElementById('main-navbar');
      const siteBanner = document.getElementById('site-banner');
      if (navbar && siteBanner) {
        navbar.style.top = `0`;
        siteBanner.style.paddingTop = `${NAVBAR_HEIGHT}px`;
        navbar.setAttribute('scroll-hidden', 'false');
      }
    }
  }, [heightBP]);

  return (
    <div id="site-banner">
      {user.userId !== '' ? (
        <Routes>
          <Route path="/">
            <Route index element={null} />
            {['/course/:courseId/*', '/*'].map((path) => (
              <Route key={path} path={path} element={<MainNavbar user={user} path={path} />} />
            ))}
          </Route>
        </Routes>
      ) : null}
      <Routes>
        <Route path="/">
          <Route index element={null} />
          {homePaths.map((path) => (
            <Route key={path} path={path} element={<HomeBanner />} />
          ))}
          <Route path="/user/:userId" element={<UserBanner />} />
          <Route path="*" element={<></>} />
        </Route>
      </Routes>
    </div>
  );
}

export default SiteBanner;
