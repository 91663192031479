import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import CommentLibrary from './CommentLibrary';
import RatingLibrary from './RatingLibrary';
import RubricLibrary from './RubricLibrary';
import RubricTemplatePage from './RubricTemplatePage';
import PromptEditorController from './PromptEditController';
import MiniNav from '../core/layout/MiniNav/MiniNav';
import TagsLibrary from './TagsLibrary';
import CommentPage from './CommentPage';
import RatingPage from './RatingPage';
import TagPage from './TagPage';
import DashboardStatsBar from '../core/layout/DashboardStatsBar/DashboardStatsBar';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

function Rubrics(): JSX.Element {
  const user = useSelector((state: RootState) => state.user);

  return (
    <div className="page home-page" id="rubric-library">
      <Routes>
        {['/library', '/comments', '/ratings', '/tags'].map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <>
                {user.admin || user.schoolAdmin ? <DashboardStatsBar type="RUBRIC" /> : null}
                <MiniNav id="rubrics-nav">
                  <MiniNav.Link to="/rubrics/library">Templates</MiniNav.Link>
                  <MiniNav.Link to="/rubrics/comments">Comments</MiniNav.Link>
                  <MiniNav.Link to="/rubrics/ratings">Ratings</MiniNav.Link>
                  <MiniNav.Link to="/rubrics/tags">Tags</MiniNav.Link>
                </MiniNav>
              </>
            }
          />
        ))}
      </Routes>
      <Routes>
        <Route index element={<Navigate to="/rubrics/library" />} />
        <Route path="/library" element={<RubricLibrary />} />
        <Route path="/library/rubric/:rubricTemplateId" element={<RubricTemplatePage />} />
        <Route path="/library/rubric/:rubricTemplateId/copy/comment" element={<CommentLibrary isCopy />} />
        <Route path="/library/rubric/:rubricTemplateId/copy/rating" element={<RatingLibrary isCopy />} />
        {[
          '/library/rubric/:rubricTemplateId/:actionType/:promptType/:id?/*',
          '/library/:actionType/:promptType/:id?/*',
        ].map((path) => (
          <Route key={path} path={path} element={<PromptEditorController />} />
        ))}
        <Route path="/comments" element={<CommentLibrary />} />
        <Route path="/comment/:commentId" element={<CommentPage />} />
        <Route path="/ratings" element={<RatingLibrary />} />
        <Route path="/rating/:ratingId" element={<RatingPage />} />
        <Route path="/tags" element={<TagsLibrary />} />
        <Route path="/tag/:tagId" element={<TagPage />} />
      </Routes>
    </div>
  );
}

export default Rubrics;
