import React, { useEffect, useState } from 'react';
import { AverageRating } from '../../types/types';

interface Props {
  evalAverages: AverageRating[];
}

function EvalAverageScoresCard({ evalAverages }: Props): JSX.Element {
  const [score, setScore] = useState(0);
  const [maxScore, setMaxScore] = useState(-1);

  useEffect(() => {
    let runningScore = 0;
    let runningMaxScore = 0;
    evalAverages.forEach((evalAvg) => {
      runningScore += evalAvg.averageRating;
      runningMaxScore += evalAvg.rating?.ratingLevels.length ?? -1;
    });
    setScore(runningScore);
    setMaxScore(runningMaxScore > 0 ? runningMaxScore : -1);
  }, [evalAverages]);

  return (
    <div className="eval-avg-scores-card panel-sm">
      <h2 className="title">Evaluation Scores</h2>
      <table className={`eval-rating-scores ${evalAverages.length % 2 === 0 ? 'even' : 'odd'}`}>
        <tbody>
          {evalAverages.map((evalAvg) => (
            <tr key={`rating-${evalAvg.rating?.ratingId}`}>
              <th>{evalAvg.rating?.name}</th>
              <td>
                {evalAvg.averageRating.toFixed(1)} / {evalAvg.rating?.ratingLevels.length}
              </td>
            </tr>
          ))}
          <tr id="overall">
            <th>Overall</th>
            <td>
              {score > 0 ? Math.round(score) : '—'} / {maxScore}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default EvalAverageScoresCard;
