import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { EvaluationScoreItem, EvaluationScoreItemCatalog } from '../../types/types';
import { setPageTitle } from '../../utils/functions';
import Button from '../core/button/Button/Button';
import Icon from '../core/display/Icon';
import EvalScoreboard from './EvalScoreboard';

interface Props {
  catalog: EvaluationScoreItemCatalog;
  studentSelectedEvaluations: boolean;
}

function EvalTypeSelectScreen({ catalog, studentSelectedEvaluations }: Props): JSX.Element {
  useEffect(() => setPageTitle('Evaluation Categories'), []);

  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };
  const rootPathWithIds = `/course/${courseId}/assignment/${assignmentId}/evaluate`;

  const [previewedCategory, setPreviewCategory] = useState<CategoryEntryProps | null>(null);

  const openPreview = useCallback((category: CategoryEntryProps) => setPreviewCategory(category), []);
  const closePreview = useCallback(() => setPreviewCategory(null), []);

  return (
    <>
      <div id="category-wrapper">
        <Button
          variant="alt rad low sm"
          id="back-btn"
          href={`/course/${courseId}/assignment/${assignmentId}/dashboard`}
          route
        >
          <Icon code="chevron_left" ariaHidden /> <span>Back</span>
        </Button>
        {studentSelectedEvaluations ? (
          <Button id="peer-select-btn" variant="low rad" href={`${rootPathWithIds}/select_peers`} route>
            Choose Peers to Evaluate
          </Button>
        ) : null}
        <h1>Evaluations</h1>
        <ul id="category-entry-nav" aria-label="Evaluation Categories">
          {catalog.groupEvalItem !== null ? (
            <li>
              <CategoryEntry
                title="Group"
                id="group-category"
                href={`${rootPathWithIds}/group`}
                evalScoreItems={[catalog.groupEvalItem]}
                openPreview={openPreview}
                closePreview={closePreview}
              />
            </li>
          ) : null}
          {catalog.groupMemberEvalItems.length > 0 ? (
            <li>
              <CategoryEntry
                title="Group Members"
                id="member-category"
                href={`${rootPathWithIds}/group_members`}
                evalScoreItems={catalog.groupMemberEvalItems}
                openPreview={openPreview}
                closePreview={closePreview}
              />
            </li>
          ) : null}
          {catalog.groupLeaderEvalItem !== null ? (
            <li>
              <CategoryEntry
                title="Group Leader"
                id="leader-category"
                href={`${rootPathWithIds}/group_leader`}
                evalScoreItems={[catalog.groupLeaderEvalItem]}
                openPreview={openPreview}
                closePreview={closePreview}
              />
            </li>
          ) : null}
          {catalog.instructorEvalItems.length > 0 ? (
            <li>
              <CategoryEntry
                title="Instructors"
                id="instructor-category"
                href={`${rootPathWithIds}/instructors`}
                evalScoreItems={catalog.instructorEvalItems}
                openPreview={openPreview}
                closePreview={closePreview}
              />
            </li>
          ) : null}
        </ul>
      </div>
      {previewedCategory !== null ? (
        <EvalScoreboard title={previewedCategory.title} evalScoreItems={previewedCategory.evalScoreItems} />
      ) : null}
    </>
  );
}

interface CategoryEntryProps {
  evalScoreItems: EvaluationScoreItem[];
  href: string;
  id: string;
  title: string;
  openPreview?: (category: CategoryEntryProps) => void;
  closePreview?: () => void;
}

function CategoryEntry({
  evalScoreItems,
  href,
  id,
  title,
  openPreview = () => undefined,
  closePreview = () => undefined,
}: CategoryEntryProps): JSX.Element {
  const statsData = useMemo(() => {
    const statsData = { numToDo: 0, numComplete: 0 };
    evalScoreItems.forEach((item) => (item.peerEvaluation.complete ? statsData.numComplete++ : statsData.numToDo++));
    return statsData;
  }, [evalScoreItems]);

  const isComplete = statsData.numToDo === 0;
  let touchTriggered = false;
  return (
    <Link
      className="category-entry"
      to={href}
      onTouchStart={() => (touchTriggered = true)}
      onMouseEnter={() => (!touchTriggered ? openPreview({ evalScoreItems, href, id, title }) : undefined)}
      onMouseLeave={closePreview}
      onTouchMove={closePreview}
      onClick={closePreview}
    >
      <div
        className="details-wrapper"
        id={id}
        style={{ borderLeft: isComplete ? '4px solid #55C92D' : '4px solid #2D719F' }}
      >
        <h2 aria-label={`${title} ${isComplete ? '(Complete)' : ''}`}>
          {title}
          {isComplete ? <Icon className="check-icon" code="done" ariaHidden /> : null}
        </h2>
        <div className="stats">
          <p>
            To Do: {statsData.numToDo}
            <span className="sr-only">;</span>
          </p>
          <p>Complete: {statsData.numComplete}</p>
        </div>
      </div>
      <Icon className="arrow-icon" code="chevron_right" ariaHidden />
    </Link>
  );
}

export default EvalTypeSelectScreen;
