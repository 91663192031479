import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Comment, EvaluationScoreItem, EvaluationTarget, Rating } from '../../types/types';
import { focusFirstElement, setPageTitle } from '../../utils/functions';
import { getEvalRubricComments, getEvalRubricRatings } from '../../utils/requests';
import Button from '../core/button/Button/Button';
import Avatar from '../core/display/Avatar/Avatar';
import Icon from '../core/display/Icon';
import EvalScoreboard, { RankedEvaluationScoreItem } from './EvalScoreboard';
import EvalScoresTable from './EvalScoresTable';

interface Props {
  canEvaluate: boolean;
  evalScoreItems: EvaluationScoreItem[];
  title: string;
  type: EvaluationTarget;
}

function EvalSelectScreen({ canEvaluate, evalScoreItems, title, type }: Props): JSX.Element {
  useEffect(() => setPageTitle('Select an Evaluation'), []);

  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };
  const rootPathWithIds = `/course/${courseId}/assignment/${assignmentId}/evaluate`;

  const [evalRubricComments, setEvalRubricComments] = useState<Comment[]>([]);
  const [evalRubricRatings, setEvalRubricRatings] = useState<Rating[]>([]);
  const [hoveredItem, setHoveredItem] = useState<RankedEvaluationScoreItem | null>(null);
  const [clickedItem, setClickedItem] = useState<RankedEvaluationScoreItem | null>(null);

  useEffect(() => {
    getEvalRubricComments(assignmentId, type, setEvalRubricComments);
    getEvalRubricRatings(assignmentId, type, setEvalRubricRatings);
  }, [type, assignmentId]);

  const handleSelect = (item: RankedEvaluationScoreItem) => {
    setClickedItem(item);
    requestAnimationFrame(() => {
      const previewEl = document.getElementById('eval-preview');
      if (previewEl) {
        previewEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
        focusFirstElement(previewEl);
      }
    });
  };
  const handleMouseOver = (item: RankedEvaluationScoreItem) => setHoveredItem(item);
  const handleMouseOut = useCallback(() => setHoveredItem(null), []);

  const selectedItem = hoveredItem || clickedItem || null;

  return (
    <>
      <div className="scoreboard-wrapper">
        <Button
          variant="alt rad low sm"
          id="back-btn"
          href={`/course/${courseId}/assignment/${assignmentId}/evaluate/all`}
          route
        >
          <Icon code="chevron_left" ariaHidden /> <span>Back</span>
        </Button>
        <h1>{title}</h1>
        <EvalScoreboard
          title={title}
          evalScoreItems={evalScoreItems}
          onSelect={handleSelect}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        />
      </div>
      {selectedItem !== null ? (
        <EvalPreview
          canEvaluate={canEvaluate}
          title={title}
          rubricComments={evalRubricComments}
          rubricRatings={evalRubricRatings}
          rootPathWithIds={rootPathWithIds}
          {...selectedItem}
        />
      ) : null}
    </>
  );
}

interface EvalPreviewProps extends RankedEvaluationScoreItem {
  canEvaluate: boolean;
  rubricComments: Comment[];
  rubricRatings: Rating[];
  rootPathWithIds: string;
  title: string;
}

function EvalPreview({
  canEvaluate,
  rubricComments,
  rubricRatings,
  targetUser,
  peerEvaluation,
  rank,
  rootPathWithIds,
  score,
  title,
}: EvalPreviewProps): JSX.Element {
  const evalRatingIdToScoreMap = useMemo(() => {
    const map: { [index: string]: number } = {};
    peerEvaluation?.reviewRatings.forEach((reviewRating) => (map[reviewRating.ratingId] = reviewRating.score));
    return map;
  }, [peerEvaluation]);

  return (
    <div id="eval-preview">
      <div className="title-row">
        {targetUser !== null ? (
          <>
            <Avatar size={40} user={targetUser} />
            <h2>{targetUser.name}</h2>
          </>
        ) : (
          <h2>{title}</h2>
        )}
      </div>
      <div id="preview-body">
        <div className="rank-row">
          {score !== -1 ? (
            <p>
              Current Placement:{' '}
              <span className="rank" id={`rank-${rank}`}>
                {String(rank).padStart(2, '0')}
              </span>
            </p>
          ) : !peerEvaluation.complete ? (
            <p>Evaluation Required</p>
          ) : null}
        </div>

        {rubricRatings.length > 0 ? (
          <EvalScoresTable rubricRatings={rubricRatings} ratingIdToScoreMap={evalRatingIdToScoreMap} />
        ) : null}

        {rubricComments.length > 0 ? (
          <table id="eval-comments">
            <tbody>
              <tr>
                <th>
                  {peerEvaluation?.reviewComments.length} Comment{peerEvaluation?.reviewComments.length > 1 ? 's' : ''}
                </th>
              </tr>
            </tbody>
          </table>
        ) : null}
      </div>

      <Button
        variant="rad"
        href={canEvaluate ? `${rootPathWithIds}/${peerEvaluation?.peerEvalId}` : undefined}
        route
        disabled={!canEvaluate}
      >
        Evaluate {!canEvaluate ? 'Unavailable' : ''}
      </Button>
    </div>
  );
}

export default EvalSelectScreen;
