import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { RootState } from '../../store';
import { setPageTitle } from '../../utils/functions';
import AdminCoursesMenu from './AdminCoursesMenu';
import AdminDashboard from './AdminDashboard';
import AdminPurchasing from './AdminPurchasing';
import UserLibrary from './UserLibrary';

function AdminController(): JSX.Element {
  useEffect(() => setPageTitle('Admin'), []);

  const user = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.admin) navigate('/');
  }, [navigate, user]);

  if (user.admin) {
    return (
      <Routes>
        <Route path="/dashboard" element={<AdminDashboard />} />
        <Route path="/courses" element={<AdminCoursesMenu />} />
        <Route path="/users" element={<UserLibrary />} />
        <Route path="/purchasing" element={<AdminPurchasing />} />
        <Route path="/" element={<Navigate to="/admin/dashboard" />} />
      </Routes>
    );
  }
  return <></>;
}

export default AdminController;
