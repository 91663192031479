import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  CommentData,
  CommentWithReviewComments,
  RatingResource,
  RatingScore,
  Result,
  ReviewCommentWithName,
  SubmissionInfo,
} from '../../types/types';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getMyGradeResults,
  getReflection,
  getResourcesForStudent,
  getSortedReviewComments,
  getStudentCommentsReceivedByPrompt,
  getStudentRatingScores,
  getSubmissionInfo,
  postReflection,
} from '../../utils/requests';
import './_reflect.scss';
import TabList from '../core/layout/TabList/TabList';
import RatingScoresCard from '../results/RatingScoresCard';
import PeerCommentsCard from '../results/PeerCommentsCard';
import StudentResultsTutorial from '../tutorial/StudentResultsTutorial';
import ReviewSubmission from '../core/display/Submission/ReviewSubmission';
import Form from './Form';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { genReflectionCommentTable } from '../../utils/functions';
import { setCommentTable, setSaveTimestamp, updatePreviousTables } from '../../actions';
import _ from 'lodash';
import moment from 'moment';
import {
  SAVE_DEBOUNCE_MAX_WAIT,
  SAVE_DEBOUNCE_WAIT,
  SCREEN_WIDTH_LAPTOP_SM,
  SCREEN_WIDTH_TABLET,
} from '../../utils/constants';

function ReflectionPage(): JSX.Element {
  const { courseId, assignmentId, reflectionId } = useParams() as {
    courseId: string;
    assignmentId: string;
    reflectionId: string;
  };
  const [peerComments, setPeerComments] = useState<CommentWithReviewComments[]>([]);
  const [resources, setResources] = useState<RatingResource[]>([]);
  const [result, setResult] = useState<Result | null>(null);
  const [ratingScores, setRatingScores] = useState<RatingScore[]>([]);
  const [submission, setSubmission] = useState<SubmissionInfo | null>(null);
  const [submissionCollapsed, setSubmissionCollapsed] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [submissionId, setSubmissionId] = useState<string | null>(null);
  const [onMobile, setOnMobile] = useState(window.innerWidth < SCREEN_WIDTH_LAPTOP_SM);
  const [reviewCommentWithName, setReviewCommentWithName] = useState<ReviewCommentWithName[]>([]);

  // Redux:
  const dispatch = useDispatch();
  const commentTable = useSelector((state: RootState) => state.commentTable);
  const previousTables = useSelector((state: RootState) => state.previousTables);

  const navigate = useNavigate();

  useEffect(() => {
    getMyGradeResults(assignmentId, setResult);
    getStudentRatingScores(assignmentId, setRatingScores);
    getResourcesForStudent(assignmentId, setResources);
    getStudentCommentsReceivedByPrompt(assignmentId, setPeerComments);
    getSortedReviewComments(assignmentId, setReviewCommentWithName);
    if (submissionId) getSubmissionInfo(submissionId, setSubmission);
  }, [assignmentId, submissionId, setReviewCommentWithName]);

  const handleLoad = useCallback(() => {
    setLoaded(true);
  }, []);

  /**
   * Loads and stores comments in format usable for CommentBox components.
   * Stores in Redux state management
   * @param {object[]} commentData
   */
  const loadReflectionComments = useCallback(
    (reflectionComment: CommentData[]) => {
      genReflectionCommentTable(reflectionComment, (commentTable) => {
        // Store comment table
        dispatch(setCommentTable(commentTable));
        // Store clone as the previous table
        dispatch(updatePreviousTables({ commentTable: _.cloneDeep(commentTable) }));
      });
    },
    [dispatch],
  );

  /**
   * Once a valid reflection ID loaded into state, load the reflection
   */
  useEffect(() => {
    if (reflectionId !== '') {
      getReflection(assignmentId, (reflection) => {
        loadReflectionComments(reflection.reviewComments);
        setSubmissionId(reflection.submissionId);
      });
    }
  }, [reflectionId, assignmentId, loadReflectionComments]);

  /**
   * Compiles the reflection comment and save data from the Redux store
   * and sends it to the server via POST request.
   *
   * This function is debounced and passed to children for use
   * via props.
   */
  const saveReflection = useCallback(
    (successCb?: () => void) => {
      // Make sure there are changes to save before saving
      if (!_.isEqual(previousTables.commentTable, commentTable)) {
        // Compile reflection comment data from table
        const reflectionCommentData = [] as CommentData[];
        for (const commentId in commentTable) {
          for (const innerIndex in commentTable[commentId]) {
            const comment = commentTable[commentId][innerIndex].comment;
            reflectionCommentData.push({
              reviewId: reflectionId ? reflectionId : '', // This should be assigned appropriately
              commentId: commentId,
              commentNumber: 1,
              comment: comment,
              pinDrop: null,
            });
          }
        }

        // Save current tables as previous tables for next save time
        dispatch(
          updatePreviousTables({
            commentTable: _.cloneDeep(commentTable),
          }),
        );

        // Send data to server
        postReflection(reflectionCommentData, assignmentId, reflectionId, () => {
          // Set saved timestamp
          dispatch(setSaveTimestamp(moment().format('h:mm:ss A')));
          if (successCb) successCb();
        });
      } else {
        if (successCb) successCb();
      }
    },
    [previousTables.commentTable, commentTable, dispatch, assignmentId, reflectionId],
  );

  const debouncedSaveReflection = useMemo(
    () =>
      _.debounce(saveReflection, SAVE_DEBOUNCE_WAIT, {
        maxWait: SAVE_DEBOUNCE_MAX_WAIT,
      }),
    [saveReflection],
  );

  useEffect(() => debouncedSaveReflection(), [debouncedSaveReflection, commentTable]);

  useEffect(() => {
    return () => {
      debouncedSaveReflection.cancel();
    };
  }, [debouncedSaveReflection]);

  useEffect(() => {
    const handleResize = () => {
      const currMobileState = window.innerWidth < SCREEN_WIDTH_TABLET;
      if (onMobile !== currMobileState) setOnMobile(currMobileState);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [onMobile]);

  const layoutClass = submissionCollapsed || onMobile ? 'column' : 'row';

  return (
    <>
      {!loaded ? <LoadingSpinner /> : null}
      <div id="reflection-page" className={`page ${layoutClass}`}>
        <div id="reflection-panel-left">
          <TabList
            label="Reflection Menu"
            tabs={
              <>
                <TabList.Tab id="comment" controls="comment-tab">
                  Comments
                </TabList.Tab>
                <TabList.Tab id="rating" controls="rating-tab">
                  Average Rating
                </TabList.Tab>
                <TabList.Tab id="submission" controls="submission-tab">
                  Submission
                </TabList.Tab>
                <TabList.Tab id="resources" controls="resources-tab">
                  Resources
                </TabList.Tab>
              </>
            }
          >
            <TabList.TabPanel id="comment-tab" labeledBy="comment">
              <div className="results-container fadeIn">
                <div className="flex-col primary-section">
                  <div className="rating-card">
                    <PeerCommentsCard peerComments={peerComments} reviewCommentWithName={reviewCommentWithName} />
                  </div>
                </div>
              </div>
            </TabList.TabPanel>
            <TabList.TabPanel id="rating-tab" labeledBy="rating">
              <div className="results-container fadeIn">
                <div className="flex-col primary-section">
                  <div className="rating-card">
                    <RatingScoresCard ratingScores={ratingScores} result={result ?? undefined} size="long" />
                  </div>
                </div>
              </div>
            </TabList.TabPanel>

            <TabList.TabPanel id="submission-tab" labeledBy="submission">
              {submission ? (
                <ReviewSubmission
                  submissionInfo={submission}
                  onChange={(state) => setSubmissionCollapsed(state.collapsed)}
                  enableReport
                />
              ) : null}
            </TabList.TabPanel>
            <TabList.TabPanel id="resources-tab" labeledBy="resources">
              {resources.length > 0 ? (
                <div className="results-container fadeIn">
                  <div className="flex-col primary-section">
                    <div className="rating-card">
                      <StudentResultsTutorial resourcesAvailable={resources.length > 0} />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="rating-card">No resources available</div>
                </>
              )}
            </TabList.TabPanel>
          </TabList>
        </div>
        <div id="reflection-panel-right">
          <Form
            assignmentId={assignmentId}
            saveReflection={debouncedSaveReflection}
            onLoad={handleLoad}
            onSubmit={() => {
              saveReflection(() => {
                navigate(`/course/${courseId}/assignment/${assignmentId}/dashboard`);
              });
            }}
          />
        </div>
      </div>
    </>
  );
}

export default ReflectionPage;
