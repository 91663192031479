import _ from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import Icon from '../../display/Icon';

interface Props {
  children: React.ReactNode;
  hideLabel?: boolean;
  label?: string;
  setFilterList: React.Dispatch<React.SetStateAction<string[]>>;
}

function FilterTab({ children, hideLabel = false, label = 'Filter by:', setFilterList }: Props): JSX.Element {
  const [init, setInit] = useState(false);

  const handleChecked = (e: React.FormEvent) => {
    const targetEl = e.target as HTMLInputElement;
    if (targetEl) {
      const type = targetEl.type;
      const checked = targetEl.checked;
      const nextEl = targetEl.nextSibling as Element;
      if (nextEl) {
        const span = nextEl.querySelectorAll('span')[0];
        const text = span.textContent;
        setFilterList((prevList) => {
          let newList = type === 'radio' ? [] : [...prevList];

          // Add text to list if checked
          if (checked && text) {
            if (!newList.includes(text)) newList.push(text);
          }
          // Remove text from list if unchecked
          else {
            newList = newList.filter((item) => item !== text);
          }

          return newList;
        });
      }
    }
  };

  type Child = React.ReactChild & { props: { defaultChecked: boolean; children: string } };

  useEffect(() => {
    if (children && !init) {
      const childArray = React.Children.toArray(children) as Child[];
      const initFilterList: string[] = [];
      childArray.forEach((child: Child) => {
        if (child.props.defaultChecked) initFilterList.push(child.props.children as string);
      });
      setFilterList(initFilterList);
      setInit(true);
    }
  }, [children, init, setFilterList]);

  return (
    <fieldset className="filter-tab flex-row" onChange={handleChecked}>
      <legend className={`filter-label${hideLabel ? ' sr-only' : ''}`}>
        <b>{label}</b>
      </legend>
      {children}
    </fieldset>
  );
}

interface ButtonProps {
  children: React.ReactNode;
  defaultChecked?: boolean;
  iconCode?: string;
  id?: string;
  name?: string;
  onChange?: (e: React.ChangeEvent) => void;
  tabIndex?: number;
  type: 'checkbox' | 'radio';
}

function Button({ children, defaultChecked, iconCode, id, name, onChange, tabIndex, type }: ButtonProps): JSX.Element {
  const uniqueId = useRef(_.uniqueId());
  return (
    <div className={`filter-btn ${id}`}>
      <input
        id={`${id}-${uniqueId.current}`}
        type={type}
        name={name}
        defaultChecked={defaultChecked}
        onChange={onChange}
        tabIndex={tabIndex}
      />
      <label htmlFor={`${id}-${uniqueId.current}`}>
        {iconCode ? <Icon className="header-icon" code={iconCode} ariaHidden /> : ''}
        <span>{children}</span>
      </label>
    </div>
  );
}

FilterTab.Button = Button;

export default FilterTab;
