import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../core/button/Button/Button';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import AssignmentTutorial from './AssignmentTutorial';
import { KEY_TUTORIAL_SEEN } from '../../utils/constants';
import { setPageTitle, storageAvailable } from '../../utils/functions';
import Icon from '../core/display/Icon';

function AssignmentMenu(): JSX.Element {
  useEffect(() => setPageTitle('New Assignment Menu'), []);

  const courseId = (useParams() as { courseId: string }).courseId;

  const { modalDispatch } = useModalContext();
  const navigate = useNavigate();

  const openTutorial = useCallback(
    (skippable: boolean) =>
      modalDispatch(
        openModal({
          heading: 'Assignment Tutorial',
          headingHide: true,
          noCancel: !skippable,
          closeButton: skippable,
          noActionButtons: true,
          form: false,
          padding: '2rem',
          children: <AssignmentTutorial />,
        }),
      ),
    [modalDispatch],
  );

  useEffect(() => {
    const flagValue = 'true';
    const tutorialSeen =
      storageAvailable('localStorage') && window.localStorage.getItem(KEY_TUTORIAL_SEEN) === flagValue;
    if (tutorialSeen === false && process.env.REACT_APP_ENVIRONMENT !== 'test') {
      openTutorial(false);
      if (storageAvailable('localStorage')) window.localStorage.setItem(KEY_TUTORIAL_SEEN, flagValue);
    }
  }, [openTutorial]);

  return (
    <div className="page assignment-row">
      <div className="panel-white assignment-menu">
        <h1>Choose an assignment creation method</h1>
        <button className="button-mini exit-btn" type="button" onClick={() => navigate(-1)} aria-label="Go back">
          <Icon code="close" ariaHidden />
        </button>
        <Button variant="rad" id="tutorial-btn" onClick={() => openTutorial(true)}>
          Tutorial
        </Button>
        <section className="button-menu">
          <Button
            id="pre-btn"
            className="recommended-btn"
            classOverride
            href={`/course/${courseId}/assignment/setup/workflows`}
            route
          >
            <span>
              Choose a <b>Standard Workflow</b>
            </span>
          </Button>

          <p id="or">OR</p>

          <Button id="new-btn" variant="rad alt low" href={`/course/${courseId}/assignment/setup/start`} route>
            Build a Custom Assignment
          </Button>
          <Button id="copy-btn" variant="rad alt low" href={`/course/${courseId}/assignment/setup/copy-list`} route>
            Copy an Assignment
          </Button>
        </section>
      </div>
    </div>
  );
}

export default AssignmentMenu;
