import React from 'react';
import { Assignment } from '../../types/types';
import studentSubIcon from '../../assets/assignment-settings-icons/stu-sub.svg';
import groupSubIcon from '../../assets/assignment-settings-icons/grp-sub.svg';
import teacherSubIcon from '../../assets/assignment-settings-icons/tch-sub.svg';
import studentRevIcon from '../../assets/assignment-settings-icons/stu-rev.svg';
import teacherRevIcon from '../../assets/assignment-settings-icons/tch-rev.svg';
import genFeedbackIcon from '../../assets/assignment-settings-icons/gen-fbk.svg';
import genEvalIcon from '../../assets/assignment-settings-icons/gen-eva.svg';
import genReflectIcon from '../../assets/assignment-settings-icons/gen-ref.svg';

interface Props {
  assignment: Assignment;
  scale?: number;
}

function AssignmentDiagram({ assignment, scale = 1 }: Props): JSX.Element {
  const {
    peerEvaluationOnly,
    groupsEnabled,
    instructorUpload,
    peerEvaluationEnabled,
    instructorGradedOnly,
    feedbackEnabled,
    reflectionEnabled
  } = assignment;

  const getSubmitIcon = () => {
    if (peerEvaluationOnly) {
      return null;
    } else if (groupsEnabled) {
      return (
        <figure>
          <img src={groupSubIcon} width={160 * scale} alt="Group Submission" />
          <figcaption>Group Submission</figcaption>
        </figure>
      );
    } else if (instructorUpload) {
      return (
        <figure>
          <img src={teacherSubIcon} width={160 * scale} alt="Instructor Submission" />
          <figcaption>Instructor Submission</figcaption>
        </figure>
      );
    } else {
      return (
        <figure>
          <img src={studentSubIcon} width={160 * scale} alt="Individual Submission" />
          <figcaption>Individual Submission</figcaption>
        </figure>
      );
    }
  };

  const getReviewIcon = () => {
    if (peerEvaluationOnly) {
      return null;
    } else if (instructorGradedOnly) {
      return (
        <figure>
          <img src={teacherRevIcon} width={240 * scale} alt="Instructor-Graded Only" />
          <figcaption>Instructor-Graded Only</figcaption>
        </figure>
      );
    } else {
      return (
        <figure>
          <img src={studentRevIcon} width={240 * scale} alt="Peer Review" />
          <figcaption>Peer Review</figcaption>
        </figure>
      );
    }
  };

  const getFeedbackIcon = () => {
    if (feedbackEnabled) {
      return (
        <figure>
          <img src={genFeedbackIcon} width={240 * scale} alt="Feedback" />
          <figcaption>Feedback</figcaption>
        </figure>
      );
    } else {
      return null;
    }
  };

  const getEvaluateIcon = () => {
    if (peerEvaluationEnabled) {
      return (
        <figure>
          <img src={genEvalIcon} width={240 * scale} alt="Team Member Evaluation" />
          <figcaption>Team Member Evaluation</figcaption>
        </figure>
      );
    }
  };

  const getReflectionIcon = () =>{
    if(reflectionEnabled) {
      return (
        <figure>
          <img src={genReflectIcon} width={240 * scale} alt="Reflection" />
          <figcaption>Reflect</figcaption>
        </figure>
      );
    }
  }

  const submitIcon = getSubmitIcon(),
    reviewIcon = getReviewIcon(),
    feedbackIcon = getFeedbackIcon(),
    evaluateIcon = getEvaluateIcon(),
    reflectionIcon = getReflectionIcon();

  return (
    <div className="assignment-diagram">
      {submitIcon}
      {reviewIcon}
      {feedbackIcon}
      {evaluateIcon}
      {reflectionIcon}
    </div>
  );
}

export default AssignmentDiagram;
