import React, { useEffect, useState } from 'react';
import { EvaluationScoreItem } from '../../types/types';
import { handleKeySelect } from '../../utils/functions';
import Avatar from '../core/display/Avatar/Avatar';
import Icon from '../core/display/Icon';

export interface RankedEvaluationScoreItem extends EvaluationScoreItem {
  rank: number;
}

interface Props {
  evalScoreItems: EvaluationScoreItem[];
  onSelect?: (item: RankedEvaluationScoreItem) => void;
  onMouseOver?: (item: RankedEvaluationScoreItem) => void;
  onMouseOut?: (item: RankedEvaluationScoreItem) => void;
  title: string;
}

function EvalScoreboard({
  evalScoreItems,
  onSelect = () => undefined,
  onMouseOver = () => undefined,
  onMouseOut = () => undefined,
  title,
}: Props): JSX.Element {
  const [scoreboard, setScoreboard] = useState<EvaluationScoreItem[]>([]);
  const [unranked, setUnranked] = useState<EvaluationScoreItem[]>([]);

  useEffect(() => {
    if (evalScoreItems !== null) {
      const scoreboard: EvaluationScoreItem[] = [];
      const unranked: EvaluationScoreItem[] = [];
      evalScoreItems.forEach((item) => (item.peerEvaluation.complete ? scoreboard.push(item) : unranked.push(item)));
      setScoreboard(
        scoreboard.sort((a, b) => {
          if (a.score < b.score) return 1;
          else if (a.score > b.score) return -1;
          else if (a.targetUser && b.targetUser)
            return (a.targetUser.name || '').localeCompare(b.targetUser.name || '');
          return 0;
        }),
      );
      setUnranked(
        unranked.sort((a, b) =>
          a.targetUser && b.targetUser ? (a.targetUser.name || '').localeCompare(b.targetUser.name || '') : 0,
        ),
      );
    }
  }, [evalScoreItems]);

  const mapEventProps = (item: RankedEvaluationScoreItem) => {
    return {
      onSelect: () => onSelect(item),
      onMouseOver: () => onMouseOver(item),
      onMouseOut: () => onMouseOut(item),
    };
  };

  let rank = 1;
  return (
    <div className="eval-scoreboard">
      <ul aria-label="Complete Evaluations">
        {scoreboard.map((scoreItem, i) => {
          if (i < 1 || scoreItem.score !== scoreboard[i - 1].score) rank = i + 1;
          return (
            <li key={`score-entry-${scoreItem.peerEvaluation.peerEvalId}`}>
              <ScoreboardEntry rank={rank} {...scoreItem} {...mapEventProps({ ...scoreItem, rank })} title={title} />
            </li>
          );
        })}
      </ul>
      {unranked.length > 0 ? <h2 id="num-unranked">{unranked.length} Evaluations Required:</h2> : null}
      <ul aria-label="Incomplete Evaluations">
        {unranked.map((scoreItem) => (
          <li key={`score-entry-${scoreItem.peerEvaluation.peerEvalId}`}>
            <ScoreboardEntry
              className="unranked-entry"
              rank={-1}
              {...scoreItem}
              {...mapEventProps({ ...scoreItem, rank })}
              title={title}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

interface ScoreboardEntryProps extends EvaluationScoreItem {
  className?: string;
  onSelect: () => void;
  onMouseOut: () => void;
  onMouseOver: () => void;
  rank: number;
  title: string;
}

function ScoreboardEntry({
  className,
  targetUser,
  onSelect,
  onMouseOut,
  onMouseOver,
  peerEvaluation,
  rank,
  score,
  title,
}: ScoreboardEntryProps): JSX.Element {
  const [touchTriggered, setTouchTriggered] = useState(false);

  return (
    <div
      role="button"
      className={`score-entry ${className ?? ''}`}
      onTouchStart={() => setTouchTriggered(true)}
      onMouseOver={onMouseOver}
      onFocus={onMouseOver}
      onMouseOut={!touchTriggered ? onMouseOut : undefined}
      onBlur={onMouseOut}
      onClick={onSelect}
      onKeyDown={(e) => handleKeySelect(e, onSelect)}
      tabIndex={0}
      aria-label={`${targetUser?.name}; ${rank < 0 ? 'Unranked' : `Rank ${rank}`}; ${
        peerEvaluation.complete
          ? score < 0
            ? `${peerEvaluation.reviewComments.length} Comments;`
            : `${score} points;`
          : ''
      } Select evaluation`}
    >
      <span className="details-wrapper">
        {peerEvaluation.complete ? (
          <div className="rank" id={`rank-${rank}`}>
            {String(rank).padStart(2, '0')}
          </div>
        ) : (
          <div className="rank unranked">00</div>
        )}
        {targetUser !== null ? (
          <>
            <Avatar size={32} user={targetUser} />
            <span className="name">{targetUser.name}</span>
          </>
        ) : (
          <span className="name">{title}</span>
        )}
      </span>
      <span className="status-wrapper">
        {peerEvaluation.complete ? (
          <span className="score">
            {score < 0 ? (
              <>
                {peerEvaluation.reviewComments.length}{' '}
                <span className="points">Comment{peerEvaluation.reviewComments.length > 1 ? 's' : ''}</span>
              </>
            ) : (
              <>
                {score} <span className="points">pts</span>
              </>
            )}
          </span>
        ) : (
          <Icon className="action-icon" code="arrow_forward" ariaHidden />
        )}
      </span>
      {peerEvaluation.complete ? <Icon className="done-icon" code="done" /> : null}
    </div>
  );
}

export default EvalScoreboard;
