import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Tag } from '../../types/types';
import { lightenDarkenColor } from '../../utils/functions';
import Button from '../core/button/Button/Button';
import Icon from '../core/display/Icon';
import RichReader from '../core/display/RichReader';

interface Props {
  added?: boolean;
  children?: React.ReactNode;
  color?: string;
  customControls?: React.ReactNode;
  description: string;
  hiddenElements?: React.ReactNode;
  iconCode: string;
  label: string;
  name: string;
  onAdd?: () => void;
  onEdit?: () => void;
  onSelect?: () => void;
  status: string;
  tags: Tag[];
}

function Prompt({
  added = false,
  children,
  color,
  customControls,
  description,
  hiddenElements,
  iconCode,
  label,
  name,
  onAdd,
  onEdit,
  onSelect,
  status,
  tags,
}: Props): JSX.Element {
  const descElem = useRef<HTMLDivElement>(null);
  const uniqueId = useRef(_.uniqueId());

  const [showFull, setShowFull] = useState(false);
  const [descOverflows, setDescOverflows] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [descKey, setDescKey] = useState(0);

  useEffect(() => {
    requestAnimationFrame(() => {
      if (descElem.current) setDescOverflows(descElem.current.clientHeight < descElem.current.scrollHeight);
    });
  }, [showFull]);

  useEffect(() => {
    if (!showFull) setShowFullDescription(false);
  }, [showFull]);

  useEffect(() => setDescKey((prevKey) => prevKey + 1), [description]);

  return (
    <div className="prompt-display">
      <Icon className="icon" code={iconCode} style={{ backgroundColor: color }} ariaHidden />
      <div className="ctrls">
        {onEdit ? (
          <Button className="edit-btn" classOverride onClick={onEdit}>
            <Icon code="edit" ariaHidden />
            <span>edit</span>
          </Button>
        ) : null}
        {onSelect ? (
          <Button className="select-btn" classOverride onClick={onSelect}>
            <Icon code="done" ariaHidden />
            <span>select</span>
          </Button>
        ) : null}
        {onAdd ? (
          <Button className={added ? 'select-btn' : 'edit-btn'} classOverride onClick={onAdd} disabled={added}>
            <Icon code={added ? 'done' : 'playlist_add'} ariaHidden />
            <span>{added ? 'Added' : 'Add'}</span>
          </Button>
        ) : null}
        {customControls}
      </div>
      <h2 className="prompt-name">
        <span className="title">{name}</span>
        <span className="badge" style={color ? { boxShadow: `0 0 0 2px ${lightenDarkenColor(color, 50)}` } : undefined}>
          {label}
        </span>
      </h2>
      <p>
        <i>{status}</i>
      </p>
      <div
        className={`body ${showFull ? 'more' : 'less'}`}
        style={showFull ? { maxHeight: 'none' } : undefined}
        id={`prompt-body-${uniqueId.current}`}
      >
        <div
          className="description-wrapper"
          style={showFullDescription ? { maxHeight: 'none' } : undefined}
          ref={descElem}
        >
          <RichReader key={descKey} content={description} />
        </div>
        {descOverflows ? (
          <Button
            className={`button-mini ${showFullDescription ? 'desc-less' : 'desc-more'}`}
            onClick={() => setShowFullDescription((prev) => !prev)}
            classOverride
            ariaLabel={`${showFullDescription ? 'Hide' : 'Show'} Full Description`}
          >
            {showFullDescription ? 'Hide' : 'more_horiz'}
          </Button>
        ) : null}
        {children}
        <div className="hidden-elements">
          {hiddenElements}
          <div className="tags">
            {(tags ?? []).map((tag) => (
              <span key={tag.tagId}>{tag.content}</span>
            ))}
          </div>
        </div>
      </div>
      <button
        className={`toggle-body-size ${showFull ? 'less' : 'more'}`}
        onClick={() => setShowFull((prevState) => !prevState)}
        aria-expanded={showFull}
        aria-controls={`prompt-body-${uniqueId.current}`}
      >
        <Icon code={showFull ? 'remove' : 'add'} ariaHidden /> {showFull ? 'Show Less' : 'Show Full'}
      </button>
    </div>
  );
}

export default Prompt;
