import React, { useCallback, useEffect, useState } from 'react';
import { CellProps } from 'react-table';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { Group, UserWithGroupInfo } from '../../types/types';
import { formDataToObject } from '../../utils/functions';
import { addUserToGroup, getAssignmentGroups, getGroupsRoster } from '../../utils/requests';
import Table, { CustomColumn } from '../core/display/Table/Table';
import FilterTab from '../core/layout/FilterTab/FilterTab';
import { useSelector } from 'react-redux';
import { selectAssignment } from '../../store/selectors';

interface Props {
  assignmentId: string;
  circles?: boolean;
}

type TableData = {
  groupId: string | null;
  groupName: string | null;
  userId: string;
  userName: string;
};

function TeacherGroupsStudentList({ assignmentId, circles }: Props): JSX.Element {
  const [updateKey, setUpdateKey] = useState(0);
  const [groupsRoster, setGroupsRoster] = useState<UserWithGroupInfo[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tableColumns, setTableColumns] = useState<CustomColumn<TableData>[]>([]);
  const [filterList, setFilterList] = useState<string[]>([]);

  const assignment = useSelector(selectAssignment);
  const { modalDispatch } = useModalContext();

  useEffect(() => {
    getGroupsRoster(assignmentId, setGroupsRoster);
  }, [assignmentId, updateKey]);

  useEffect(() => {
    getAssignmentGroups(assignmentId, setGroups);
  }, [assignmentId, updateKey]);

  const forceUpdate = useCallback(() => {
    setUpdateKey((prevKey) => prevKey + 1);
  }, []);

  useEffect(() => {
    const columns: CustomColumn<TableData>[] = [
      { Header: 'Name', accessor: 'userName', className: 'left-align' },
      {
        Header: circles ? 'Circle' : 'Group',
        accessor: 'groupName',
        className: 'left-align',
        Cell: function render({ cell: { value } }: CellProps<TableData>) {
          if (value === null) return <span className="without-group">Add to {circles ? 'Circle' : 'Group'}</span>;
          return <>{value}</>;
        },
      },
    ];
    const dataTable: TableData[] = [];
    groupsRoster
      .filter((rosterEntry) => {
        if (filterList[0] === `Without ${circles ? 'Circle' : 'Group'}`) return rosterEntry.groupId === null;
        return true;
      })
      .forEach((rosterEntry) => {
        const newRow: TableData = {
          groupId: rosterEntry.groupId,
          groupName: rosterEntry.group?.groupName ?? null,
          userId: rosterEntry.user.userId,
          userName: rosterEntry.user.sortableName,
        };

        dataTable.push(newRow);
      });

    setTableData(dataTable);
    setTableColumns(columns);
  }, [groupsRoster, filterList, circles]);

  const requestAssignToGroup = useCallback(
    (userId: string, groupId: string) => {
      addUserToGroup(assignmentId, userId, groupId, forceUpdate);
    },
    [assignmentId, forceUpdate],
  );

  return (
    <Table
      columns={tableColumns}
      data={tableData}
      title={`Student ${circles ? 'Circle' : 'Group'} Membership`}
      id="student-group-membership-table"
      informOfRow={(row) => {
        const { userName, userId, groupId } = row.original;
        modalDispatch(
          openModal({
            heading: 'Assign Group',
            inputType: 'none',
            buttonText: 'Assign',
            children: (
              <div id="assign-group-modal-content">
                <label htmlFor="group-assign">{`Assign "${userName}" to group:`}</label>
                <select id="group-assign" name="groupId" defaultValue="" required>
                  <option value="" disabled>
                    Select a group
                  </option>
                  {groups
                    .filter(
                      (group) =>
                        group.groupId !== groupId && group.groupMembers.length < (assignment?.groupSizeLimit ?? 100),
                    )
                    .map((group) => (
                      <option key={`group-${group.groupId}`} value={group.groupId}>
                        {group.groupName}
                      </option>
                    ))}
                </select>
              </div>
            ),
            onSubmit: (formData) => {
              const { groupId } = formDataToObject(formData) as { groupId: string };
              requestAssignToGroup(userId, groupId);
            },
          }),
        );
      }}
    >
      <FilterTab label="Show Students:" setFilterList={setFilterList}>
        <FilterTab.Button id="btn-all" type="radio" name="students-filters" defaultChecked={true}>
          All
        </FilterTab.Button>
        <FilterTab.Button id="btn-solo" type="radio" name="students-filters">
          Without {circles ? 'Circle' : 'Group'}
        </FilterTab.Button>
      </FilterTab>
    </Table>
  );
}

export default TeacherGroupsStudentList;
