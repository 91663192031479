import moment from 'moment';
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { selectCourse } from '../../store/selectors';
import { Assignment, Course, RubricsTargetMap } from '../../types/types';
import { getRubricsMap } from '../../utils/requests';
import Wizard from '../core/input/Wizard/Wizard';
import AdvancedPage, { AdvancedProps } from './WizardPages/AdvancedPage';
import DeadlinesPage, { DeadlinesProps } from './WizardPages/DeadlinesPage';
import DetailsPage, { DetailsProps } from './WizardPages/DetailsPage';
import EvaluationPage, { EvaluationProps } from './WizardPages/EvaluationPage';
import FeedbackPage, { FeedbackProps } from './WizardPages/FeedbackPage';
import FinishPage from './WizardPages/FinishPage';
import GradingPage1, { Grading1Props } from './WizardPages/GradingPage1';
import GradingPage2, { Grading2Props } from './WizardPages/GradingPage2';
import GradingPage3, { Grading3Props } from './WizardPages/GradingPage3';
import GradingPage4, { Grading4Props } from './WizardPages/GradingPage4';
import ReviewPage1, { Review1Props } from './WizardPages/ReviewPage1';
import ReviewPage2, { Review2Props } from './WizardPages/ReviewPage2';
import RubricPage from './WizardPages/RubricPage';
import SubmissionPage, { SubmissionProps } from './WizardPages/SubmissionPage';

export interface AssignmentWizardPageProps<T> {
  assignmentSettings: Assignment;
  updateSettings: (changedSettings: T) => void;
  onInvalid: (heading: string, label: string) => void;
  updateValidation: (arg0: ((e: React.FormEvent) => boolean) | undefined) => void;
}

interface Props {
  initAssignment: Assignment;
  onFinish: (assignmentData: Assignment) => void;
  type: 'new' | 'edit' | 'copy' | 'workflow';
}

function AssignmentWizard({ initAssignment, onFinish, type }: Props): JSX.Element {
  const { modalDispatch } = useModalContext();
  const course = useSelector(selectCourse) as Course;

  const [validation, setValidation] = useState<((e: React.FormEvent) => boolean) | undefined>(undefined);

  const updateValidation = useCallback((arg0: ((e: React.FormEvent) => boolean) | undefined) => {
    setValidation(() => arg0);
  }, []);

  const onInvalid = useCallback(
    (heading: string, label: string) =>
      modalDispatch(
        openModal({
          heading,
          label,
          cancelHide: true,
        }),
      ),
    [modalDispatch],
  );

  const [assignmentSettings, setAssignmentSettings] = useState<Assignment>(initAssignment);
  const [rubricsMap, setRubricsMap] = useState<RubricsTargetMap>({});

  const updateSettings = useCallback(
    (
      changedSettings:
        | DetailsProps
        | SubmissionProps
        | Review1Props
        | Review2Props
        | FeedbackProps
        | EvaluationProps
        | Grading1Props
        | Grading2Props
        | Grading3Props
        | Grading4Props
        | DeadlinesProps
        | AdvancedProps,
    ) => setAssignmentSettings((prevSettings) => ({ ...prevSettings, ...changedSettings })),
    [setAssignmentSettings],
  );

  useEffect(() => {
    if (assignmentSettings.assignmentId) getRubricsMap(assignmentSettings.assignmentId, setRubricsMap);
  }, [assignmentSettings.assignmentId]);

  const sections = useMemo(() => {
    const sections = [];

    if (course) {
      const sharedProps = { assignmentSettings, updateSettings, onInvalid, updateValidation };
      const copyType = type === 'copy' || type === 'workflow';

      sections.push({
        title: 'Details',
        pages: [<DetailsPage key="page-0-0" {...sharedProps} />],
      });

      if (!copyType && !assignmentSettings.peerEvaluationOnly) {
        sections.push({
          title: 'Submission',
          pages: [<SubmissionPage key="page-1-0" {...sharedProps} />],
        });
      }

      const reviewPages = [];
      if (
        !copyType &&
        !course.asyncEnabled &&
        !assignmentSettings.instructorGradedOnly &&
        !assignmentSettings.peerEvaluationOnly
      ) {
        reviewPages.push(<ReviewPage1 key="page-2-0" {...sharedProps} />);
        if (!assignmentSettings.instructorUpload) reviewPages.push(<ReviewPage2 key="page-2-1" {...sharedProps} />);
      }
      if (reviewPages.length > 0)
        sections.push({
          title: 'Review',
          pages: reviewPages,
        });

      if (!copyType && assignmentSettings.feedbackEnabled) {
        sections.push({
          title: 'Feedback',
          pages: [<FeedbackPage key="page-3-0" {...sharedProps} />],
        });
      }

      if (!copyType && assignmentSettings.peerEvaluationEnabled) {
        sections.push({
          title: 'Evaluation',
          pages: [<EvaluationPage key="page-4-0" {...sharedProps} />],
        });
      }

      const gradingPages = [];
      if (
        !copyType &&
        !assignmentSettings.instructorGradedOnly &&
        !course.asyncEnabled &&
        !assignmentSettings.instructorUpload
      ) {
        gradingPages.push(<GradingPage1 key="page-5-0" {...sharedProps} />);
      }
      if (
        !copyType &&
        !course.asyncEnabled &&
        !assignmentSettings.peerEvaluationOnly &&
        !assignmentSettings.instructorUpload
      ) {
        gradingPages.push(<GradingPage2 key="page-5-1" {...sharedProps} />);
      }
      if (
        !copyType &&
        !assignmentSettings.instructorGradedOnly &&
        !assignmentSettings.peerEvaluationOnly &&
        !course.asyncEnabled
      ) {
        gradingPages.push(<GradingPage3 key="page-5-2" {...sharedProps} />);
      }
      if (
        !copyType &&
        !course.asyncEnabled &&
        !assignmentSettings.instructorGradedOnly &&
        !assignmentSettings.peerEvaluationOnly &&
        !assignmentSettings.instructorUpload
      ) {
        gradingPages.push(<GradingPage4 key="page-5-3" {...sharedProps} />);
      }
      if (gradingPages.length > 0)
        sections.push({
          title: 'Grading',
          pages: gradingPages,
        });
      if (type === 'copy') {
        sections.push({ title: 'Rubric', pages: [<RubricPage key="page-1-0" rubricsMap={rubricsMap} />] });
      }
      if (!course.template) {
        sections.push({
          title: 'Deadlines',
          pages: [<DeadlinesPage key="page-6-0" edit={type === 'edit'} {...sharedProps} />],
        });
      }
      if (!copyType)
        sections.push({
          title: 'Advanced',
          pages: [<AdvancedPage key="page-7-0" {...sharedProps} />],
        });
      sections.push({
        title: 'Finish',
        pages: [<FinishPage key="page-8-0" />],
      });
    }

    return sections;
  }, [course, assignmentSettings, updateSettings, onInvalid, updateValidation, type, rubricsMap]);

  return (
    <Wizard
      name={`${type} Assignment`}
      sections={sections}
      onSubmit={validation}
      onFinish={() =>
        onFinish({
          ...assignmentSettings,
          asyncEndDeadline: moment.tz(assignmentSettings.asyncEndDeadline, course.timeZone).toISOString(),
          courseId: course.courseId as string,
          deadlineTime:
            assignmentSettings.deadlineTime.length < 8
              ? assignmentSettings.deadlineTime + ':59'
              : assignmentSettings.deadlineTime,
          feedbackDeadline: moment.tz(assignmentSettings.feedbackDeadline, course.timeZone).toISOString(),
          peerEvaluationDeadline: moment.tz(assignmentSettings.peerEvaluationDeadline, course.timeZone).toISOString(),
          publicationTime: moment.tz(assignmentSettings.publicationTime, course.timeZone).toISOString(),
          reviewDeadline: moment.tz(assignmentSettings.reviewDeadline, course.timeZone).toISOString(),
          submissionDeadline: moment.tz(assignmentSettings.submissionDeadline, course.timeZone).toISOString(),
          reflectionDeadline: moment.tz(assignmentSettings.reflectionDeadline, course.timeZone).toISOString(),
        })
      }
    />
  );
}

export default AssignmentWizard;
