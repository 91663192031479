import React, { useCallback, useEffect, useState } from 'react';
import RatingScoresCard from './RatingScoresCard';
import { useParams } from 'react-router-dom';
import { AssignmentDetailedResults, EvaluationTarget, RatingScore } from '../../types/types';
import { getEvalRatingScoresByTarget, getInstructorEvalResultsDetails } from '../../utils/requests';
import FilterTab from '../core/layout/FilterTab/FilterTab';
import TeacherIndividualScoresTable from './TeacherIndividualScoresTable';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import { TeacherResultsTabProps } from './TeacherResultsPage';

function TeacherEvaluationRatingScores({ assignment, updateKey, hideTabId }: TeacherResultsTabProps): JSX.Element {
  const { assignmentId } = useParams() as { assignmentId: string };

  const [evalTarget, setEvalTarget] = useState<EvaluationTarget>('MEMBER');
  const [groupRatingScores, setGroupRatingScores] = useState<RatingScore[] | null>(null);
  const [leaderRatingScores, setLeaderRatingScores] = useState<RatingScore[] | null>(null);
  const [instructorRatingScores, setInstructorRatingScores] = useState<RatingScore[] | null>(null);
  const [memberRatingScores, setMemberRatingScores] = useState<RatingScore[] | null>(null);
  const [groupResults, setGroupResults] = useState<AssignmentDetailedResults[] | null>(null);
  const [leaderResults, setLeaderResults] = useState<AssignmentDetailedResults[] | null>(null);
  const [instructorResults, setInstructorResults] = useState<AssignmentDetailedResults[] | null>(null);
  const [memberResults, setMemberResults] = useState<AssignmentDetailedResults[] | null>(null);
  const [filteredRatingScores, setFilteredRatingScores] = useState<RatingScore[]>([]);
  const [filterList, setFilterList] = useState<string[]>([]);

  const getSelectedRatingScores = useCallback((): RatingScore[] => {
    switch (evalTarget) {
      case 'GROUP':
        return groupRatingScores || [];
      case 'GROUP_LEADER':
        return leaderRatingScores || [];
      case 'INSTRUCTOR':
        return instructorRatingScores || [];
      case 'MEMBER':
        return memberRatingScores || [];
    }
  }, [evalTarget, groupRatingScores, leaderRatingScores, instructorRatingScores, memberRatingScores]);

  const getSelectedResults = (): AssignmentDetailedResults[] => {
    switch (evalTarget) {
      case 'GROUP':
        return groupResults || [];
      case 'GROUP_LEADER':
        return leaderResults || [];
      case 'INSTRUCTOR':
        return instructorResults || [];
      case 'MEMBER':
        return memberResults || [];
    }
  };

  useEffect(() => {
    getEvalRatingScoresByTarget(assignmentId, 'GROUP', setGroupRatingScores);
    getEvalRatingScoresByTarget(assignmentId, 'GROUP_LEADER', setLeaderRatingScores);
    getEvalRatingScoresByTarget(assignmentId, 'INSTRUCTOR', setInstructorRatingScores);
    getEvalRatingScoresByTarget(assignmentId, 'MEMBER', setMemberRatingScores);
    getInstructorEvalResultsDetails(assignmentId, 'GROUP', setGroupResults);
    getInstructorEvalResultsDetails(assignmentId, 'GROUP_LEADER', setLeaderResults);
    getInstructorEvalResultsDetails(assignmentId, 'INSTRUCTOR', setInstructorResults);
    getInstructorEvalResultsDetails(assignmentId, 'MEMBER', setMemberResults);
  }, [updateKey, assignmentId]);

  // Determine which scores to display based on filter
  useEffect(() => {
    if (filterList.length > 0) {
      const category = filterList[0];
      switch (category) {
        case 'Rating Prompts':
          setFilteredRatingScores(getSelectedRatingScores().filter((ratingScore) => ratingScore.rating !== null));
          break;
        case 'Tags':
          setFilteredRatingScores(getSelectedRatingScores().filter((ratingScore) => ratingScore.tag !== null));
          break;
        default:
          console.error('Invalid category');
      }
    } else {
      setFilteredRatingScores(getSelectedRatingScores());
    }
  }, [filterList, getSelectedRatingScores]);

  useEffect(() => {
    if (
      groupRatingScores &&
      leaderRatingScores &&
      instructorRatingScores &&
      memberRatingScores &&
      groupRatingScores.length < 1 &&
      leaderRatingScores.length < 1 &&
      instructorRatingScores.length < 1 &&
      memberRatingScores.length < 1
    )
      hideTabId('eval-scores');
  }, [groupRatingScores, leaderRatingScores, instructorRatingScores, memberRatingScores, hideTabId]);

  const key = filterList.length > 0 ? filterList[0] : undefined;

  if (
    groupRatingScores &&
    leaderRatingScores &&
    instructorRatingScores &&
    memberRatingScores &&
    groupResults &&
    leaderResults &&
    instructorResults &&
    memberResults
  ) {
    return (
      <>
        <label htmlFor="evalTarget">Choose Role to View:</label>
        <select
          name="target"
          id="evalTarget"
          required={true}
          value={evalTarget}
          onChange={(e) => setEvalTarget(e.target.value as EvaluationTarget)}
        >
          <option value="MEMBER">Group Member</option>
          {assignment.groupsEnabled ? (
            <>
              <option value="GROUP_LEADER">Group Leader</option>
              <option value="GROUP">Group</option>
            </>
          ) : null}
          {assignment.instructorEvalEnabled ? <option value="INSTRUCTOR">Instructor</option> : null}
        </select>
        <section>
          <RatingScoresCard ratingScores={filteredRatingScores} size="wide" scoresWrapperKey={key}>
            <>
              <FilterTab label="Display as:" setFilterList={setFilterList}>
                <FilterTab.Button
                  id="btn-ratings"
                  type="radio"
                  name="rubric-scores-display"
                  iconCode="format_list_numbered"
                  defaultChecked={true}
                >
                  Rating Prompts
                </FilterTab.Button>
                <FilterTab.Button id="btn-tags" type="radio" name="rubric-scores-display" iconCode="local_offer">
                  Tags
                </FilterTab.Button>
              </FilterTab>
            </>
          </RatingScoresCard>
        </section>
        <section>
          <TeacherIndividualScoresTable assignment={assignment} results={getSelectedResults()} />
        </section>
      </>
    );
  }
  return <LoadingSpinner />;
}

export default TeacherEvaluationRatingScores;
