import moment from 'moment';
import React from 'react';
import { Assignment } from '../../types/types';

interface Props {
  assignment: Assignment;
  children?: React.ReactNode;
  timeRemaining: string;
}

function PhaseNowCard({ assignment, children, timeRemaining }: Props): JSX.Element {
  const { submissionPhase, reviewPhase, feedbackPhase, reflectionPhase, evalPhase } = assignment.progressStats ?? {};
  const { asyncEnabled, asyncEndDeadline } = assignment;

  const inPhase = submissionPhase === true || reviewPhase === true || feedbackPhase === true || evalPhase === true || reflectionPhase === true;
  let phaseText = '';
  let nextDeadline = '';
  let nowClassName = '';
  if (asyncEnabled && asyncEndDeadline != null) {
    phaseText = 'Async Assignment';
    nextDeadline = asyncEndDeadline || '';
    nowClassName = 'submit';
  } else if (submissionPhase) {
    phaseText = 'Submission Phase';
    nextDeadline = assignment.submissionDeadline || '';
    nowClassName = 'submit';
  } else if (reviewPhase) {
    phaseText = 'Review Phase';
    nextDeadline = assignment.reviewDeadline || '';
    nowClassName = 'review';
  } else if (feedbackPhase) {
    phaseText = 'Feedback Phase';
    nextDeadline = assignment.feedbackDeadline || '';
    nowClassName = 'feedback';
  } else if (evalPhase) {
    phaseText = 'Team Member Evaluation Phase';
    nextDeadline = assignment.peerEvaluationDeadline || '';
    nowClassName = 'eval';
  }else if(reflectionPhase) {
    phaseText = 'Reflection Phase';
    nextDeadline = assignment.reflectionDeadline || '';
    nowClassName = 'reflection';
  }

  if (inPhase && nextDeadline !== '') {
    return (
      <div className={`now-phase ${nowClassName}`}>
        <p>
          Now: <b>{phaseText}</b>
        </p>
        <div className="indent">
          <p>
            <b>Next Deadline:</b>
            <br />
            <span>{moment.tz(nextDeadline, moment.tz.guess()).format('ddd, MMM D @ h:mm A z')}</span>
          </p>
          <p role="timer">
            <b>Time Remaining:</b>
            <br />
            <span>{timeRemaining}</span>
          </p>
        </div>
        {children}
      </div>
    );
  }
  return <></>;
}

export default PhaseNowCard;
