import React, { useCallback, useEffect, useMemo } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Group } from '../../types/types';
import { SAVE_DEBOUNCE_MAX_WAIT, SAVE_DEBOUNCE_WAIT } from '../../utils/constants';
import { setPageTitle } from '../../utils/functions';
import { updateAssignmentGroup } from '../../utils/requests';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import StudentGroupsPage from './StudentGroupsPage';
import TeacherGroupsPage from './TeacherGroupsPage';

interface Props {
  circles?: boolean;
}

function GroupsPage({ circles }: Props): JSX.Element {
  useEffect(() => setPageTitle(`Assignment ${circles ? 'Review Circles' : 'Groups'}`), [circles]);

  const { assignmentId } = useParams() as { courseId: string; assignmentId: string };

  const user = useSelector((state: RootState) => state.user);
  const { courseRole } = user;

  const rawSaveGroup = useCallback(
    (group: Group, callback: () => void = () => undefined) =>
      updateAssignmentGroup(assignmentId, group.groupId, group, callback),
    [assignmentId],
  );

  const debouncedSaveGroup = useMemo(
    () =>
      _.debounce(rawSaveGroup, SAVE_DEBOUNCE_WAIT * 2, {
        maxWait: SAVE_DEBOUNCE_MAX_WAIT,
      }),
    [rawSaveGroup],
  );

  switch (courseRole) {
    case 'TEACHER':
      return (
        <Routes>
          <Route path="/*" element={<TeacherGroupsPage saveGroup={debouncedSaveGroup} circles={circles} />} />
        </Routes>
      );
    case 'STUDENT':
      return (
        <Routes>
          <Route path="/*" element={<StudentGroupsPage saveGroup={debouncedSaveGroup} circles={circles} />} />
        </Routes>
      );
  }
  return <LoadingSpinner />;
}

export default GroupsPage;
