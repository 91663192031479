import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import _ from 'lodash';
import Icon from '../Icon';
import { handleKeySelect } from '../../../../utils/functions';

interface Props {
  fontStyle?: React.CSSProperties;
  input?: boolean;
  label?: string;
  padding?: number;
  progress: number;
  radius: number;
  setProgress?: (value: number) => void;
  size?: 'lg' | 'sm';
  stroke?: string;
  strokeWidth: number;
}

function ProgressRing({
  fontStyle,
  input,
  padding = 0,
  progress,
  radius,
  setProgress = () => undefined,
  size = 'lg',
  stroke,
  strokeWidth,
  label = `${progress}`,
}: Props): JSX.Element {
  const id = useRef(_.uniqueId('progress-ring-'));
  const intervalId = useRef(-1);

  const [animatedProgress, setAnimatedProgress] = useState(0);

  const normalizedRadius = useMemo(() => {
    return radius - strokeWidth / 2 - padding;
  }, [padding, radius, strokeWidth]);

  const circumference = useMemo(() => {
    return normalizedRadius * 2 * Math.PI;
  }, [normalizedRadius]);

  const strokeDashoffset = useMemo(() => {
    return circumference - (animatedProgress / 100) * circumference;
  }, [circumference, animatedProgress]);

  // Immediately set progress from 0 to [actual] to initiate animation
  useEffect(() => {
    requestAnimationFrame(() => {
      setAnimatedProgress(progress);
    });
  }, [progress]);

  const getColorFromProgress = () => {
    if (progress < 60)
      // F range
      return '#eb7373';
    else if (progress < 80)
      // C-D range
      return '#e9e335';
    // A-B range
    else return '#58cf7c';
  };

  const handleIncrement = useCallback(
    (initTime = 0) => {
      if (Date.now() - initTime <= 300) return;
      const inputEl = document.getElementById(`${id.current}-input`) as HTMLInputElement;
      if (inputEl) {
        const value = parseInt(inputEl.value) + 1;
        if (value < 100) setProgress(value);
        else setProgress(0);
      }
    },
    [setProgress],
  );

  const handleDecrement = useCallback(
    (initTime = 0) => {
      if (Date.now() - initTime <= 300) return;
      const inputEl = document.getElementById(`${id.current}-input`) as HTMLInputElement;
      if (inputEl) {
        const value = parseInt(inputEl.value) - 1;
        if (value >= 0) setProgress(value);
        else setProgress(100);
      }
    },
    [setProgress],
  );

  const imgAttributes: React.HTMLAttributes<HTMLDivElement> = input
    ? {}
    : { role: 'img', 'aria-label': `${progress}%` };

  return (
    <div className="progress-ring" {...imgAttributes}>
      <svg height={radius * 2} width={radius * 2}>
        <circle
          stroke={stroke ?? getColorFromProgress()}
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference + ' ' + circumference}
          style={{ strokeDashoffset, opacity: progress < 0 || progress > 100 ? 0 : 1 }}
          strokeLinecap="round"
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
      {input ? (
        <>
          <div className={`progress-number-${size}`}>
            <label className="sr-only" htmlFor={`${id.current}-input`}>
              Progress:
            </label>
            <input
              id={`${id.current}-input`}
              type="number"
              value={progress >= 0 ? progress : ''}
              placeholder="—"
              min={0}
              max={100}
              onChange={(e) => {
                let value = parseInt(e.target.value);
                if (isNaN(value) || value < 0) value = 0;
                if (value > 100) value = 100;
                setProgress(value);
                e.target.value = value + '';
              }}
            />
            <div className="spinner">
              <button
                className="button-mini"
                title="Increment number"
                aria-label="Increment number"
                onMouseDown={() => {
                  handleIncrement();
                  intervalId.current = window.setInterval(handleIncrement, 50, Date.now());
                }}
                onKeyDown={(e) => handleKeySelect(e, () => handleIncrement())}
                onMouseUp={() => clearInterval(intervalId.current)}
              >
                <Icon code="arrow_drop_up" ariaHidden />
              </button>
              <button
                className="button-mini"
                title="Decrement number"
                aria-label="Decrement number"
                onMouseDown={() => {
                  handleDecrement();
                  intervalId.current = window.setInterval(handleDecrement, 50, Date.now());
                }}
                onMouseUp={() => clearInterval(intervalId.current)}
                onKeyDown={(e) => handleKeySelect(e, () => handleDecrement())}
              >
                <Icon code="arrow_drop_down" ariaHidden />
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className={`progress-number-${size}`} style={fontStyle}>
          {label}
        </div>
      )}
    </div>
  );
}

export default ProgressRing;
