import React, { useCallback, useEffect, useState } from 'react';
import { POST_Purchase, POST_SpecialPurchase, Purchase, SpecialPurchase } from '../../types/types';
import {
  adminCreatePurchase,
  adminCreateSpecialPurchase,
  adminDeletePurchase,
  adminDeleteSpecialPurchase,
  adminGetPurchaseLog,
  adminGetSpecialPurchases,
  adminUpdateSpecialPurchase,
} from '../../utils/requests';
import TabList from '../core/layout/TabList/TabList';
import AdminPurchaseLog from './AdminPurchaseLog';
import AdminSpecialPurchases from './AdminSpecialPurchases';

function AdminPurchasing(): JSX.Element {
  const [purchases, setPurchases] = useState<Purchase[] | undefined>(undefined);
  const [specialPurchases, setSpecialPurchases] = useState<SpecialPurchase[] | undefined>(undefined);
  const [updateKey, setUpdateKey] = useState(0);

  useEffect(() => {
    adminGetPurchaseLog(setPurchases);
    adminGetSpecialPurchases(setSpecialPurchases);
  }, [updateKey]);

  const updateData = useCallback(() => setUpdateKey((prevKey) => prevKey + 1), []);

  const deletePurchase = useCallback((purchaseId: string) => adminDeletePurchase(purchaseId, updateData), [updateData]);

  const createPurchase = useCallback(
    (email: string, purchase: POST_Purchase) => adminCreatePurchase(email, purchase, updateData),
    [updateData],
  );

  const deleteSpecialPurchase = useCallback(
    (specialPurchaseId: string) => adminDeleteSpecialPurchase(specialPurchaseId, updateData),
    [updateData],
  );

  const createSpecialPurchase = useCallback(
    (specialPurchase: POST_SpecialPurchase) => adminCreateSpecialPurchase(specialPurchase, updateData),
    [updateData],
  );

  const updateSpecialPurchase = useCallback(
    (specialPurchaseId: string, specialPurchase: POST_SpecialPurchase) =>
      adminUpdateSpecialPurchase(specialPurchaseId, specialPurchase, updateData),
    [updateData],
  );

  return (
    <div id="admin-purchasing" className="page">
      <h1>Purchase Management</h1>
      <TabList
        label="Purchase Management Menu"
        tabs={
          <>
            <TabList.Tab id="purchase-log" controls="purchase-log-tab">
              Purchase Log
            </TabList.Tab>
            <TabList.Tab id="special-purchases" controls="special-purchases-tab">
              Special Purchases
            </TabList.Tab>
          </>
        }
      >
        <TabList.TabPanel id="purchase-log-tab" labeledBy="purchase-log">
          <AdminPurchaseLog purchases={purchases} createPurchase={createPurchase} deletePurchase={deletePurchase} />
        </TabList.TabPanel>
        <TabList.TabPanel id="special-purchases-tab" labeledBy="special-purchases">
          <AdminSpecialPurchases
            specialPurchases={specialPurchases}
            createSpecialPurchase={createSpecialPurchase}
            deleteSpecialPurchase={deleteSpecialPurchase}
            updateSpecialPurchase={updateSpecialPurchase}
          />
        </TabList.TabPanel>
      </TabList>
    </div>
  );
}

export default AdminPurchasing;
