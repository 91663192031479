import React, { useCallback, useMemo, useRef } from 'react';
import { Flipped, Flipper } from 'react-flip-toolkit';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import {
  Comment,
  EvaluationScoreItem,
  EvaluationScoreTimeline,
  EvaluationTarget,
  Rating,
  Rubric,
  User,
} from '../../types/types';
import { getEvalTargetFormatted } from '../../utils/functions';
import Button from '../core/button/Button/Button';
import Avatar from '../core/display/Avatar/Avatar';

export interface RankedEvaluationScoreItem extends EvaluationScoreItem {
  rank: number;
}

interface Props {
  currentPeerEvalId: string;
  pageIndex: number;
  rubric: Rubric;
  scoreTimelines: EvaluationScoreTimeline[];
  title: EvaluationTarget;
}

function LiveEvalScoreboard({ currentPeerEvalId, pageIndex, rubric, scoreTimelines, title }: Props): JSX.Element {
  const rank = useRef(1);
  const index = scoreTimelines.length > 0 ? Math.min(pageIndex, scoreTimelines[0].scoreTotalTimeline.length - 1) : 0;

  const end = useMemo(() => pageIndex >= rubric.length, [pageIndex, rubric.length]);

  const { modalDispatch } = useModalContext();

  const sortedScoreTimelines = scoreTimelines.sort((a, b) => {
    if (end) {
      if (a.scoreTotalTimeline[index] < b.scoreTotalTimeline[index]) return 1;
      else if (a.scoreTotalTimeline[index] > b.scoreTotalTimeline[index]) return -1;
      else if (a.user && b.user) return (a.user.name || '').localeCompare(b.user.name || '');
    } else {
      if (a.scoreDiffTimeline[index] < b.scoreDiffTimeline[index]) return 1;
      else if (a.scoreDiffTimeline[index] > b.scoreDiffTimeline[index]) return -1;
      else if (a.user && b.user) return (a.user.name || '').localeCompare(b.user.name || '');
    }
    return 0;
  });

  let flipKey = '';
  sortedScoreTimelines.forEach((sortedScoreTimeline) => (flipKey += sortedScoreTimeline.peerEvalId + '|'));

  const liveScoreboard = useMemo(() => {
    let headingText = 'Overall Score Ranking';
    let promptText = 'Sums of all rating scores given';
    let isCommentPrompt = false;
    if (!end) {
      headingText = 'Score Comparison';
      if (rubric[pageIndex].hasOwnProperty('commentId')) {
        promptText = `Prompt: ${(rubric[pageIndex] as Comment).commentName}`;
        isCommentPrompt = true;
      }
      if (rubric[pageIndex].hasOwnProperty('ratingId')) promptText = `Prompt: ${(rubric[pageIndex] as Rating).name}`;
    }

    return (
      <div className={`live-eval-scoreboard${isCommentPrompt ? ' off' : ''}`}>
        <h1>{headingText}</h1>
        <p>{promptText}</p>

        <Flipper flipKey={flipKey} element="ul">
          {sortedScoreTimelines.map((timelineItem, i) => {
            if (i < 1 || timelineItem.scoreTotalTimeline[index] !== scoreTimelines[i - 1].scoreTotalTimeline[index])
              rank.current = i + 1;
            return (
              <Flipped key={timelineItem.peerEvalId} flipId={timelineItem.peerEvalId}>
                <li>
                  <LiveScoreboardEntry
                    rank={rank.current}
                    user={timelineItem.user}
                    score={timelineItem.scoreTotalTimeline[index]}
                    diff={pageIndex === index ? timelineItem.scoreDiffTimeline[index] : 0}
                    title={title}
                    current={timelineItem.peerEvalId === currentPeerEvalId}
                    end={end}
                  />
                </li>
              </Flipped>
            );
          })}
        </Flipper>
      </div>
    );
  }, [end, flipKey, sortedScoreTimelines, rubric, pageIndex, index, scoreTimelines, title, currentPeerEvalId]);

  const activateMobileOpen = useCallback(
    () =>
      modalDispatch(
        openModal({
          heading: 'Live Evaluation Score Comparison',
          headingHide: true,
          buttonText: 'Continue',
          noActionButtons: true,
          closeButton: true,
          children: <div className="eval-context">{liveScoreboard}</div>,
        }),
      ),
    [liveScoreboard, modalDispatch],
  );

  return (
    <>
      {liveScoreboard}

      <Button id="mobile-open-scoreboard" variant="rad low alt sm" onClick={activateMobileOpen}>
        View Live Scores Comparison
      </Button>
    </>
  );
}

interface LiveScoreboardEntryProps {
  current: boolean;
  diff?: number;
  end: boolean;
  rank: number;
  score?: number;
  title: EvaluationTarget;
  user: User;
}

function LiveScoreboardEntry({
  current,
  diff,
  end,
  rank,
  score,
  title,
  user,
  ...rest
}: LiveScoreboardEntryProps): JSX.Element {
  const points = end ? score : diff;
  return (
    <div className={`score-entry${current ? ' current' : ''}`} {...rest} aria-current={current}>
      <span className="details-wrapper">
        {end ? (
          score !== -1 ? (
            <div className="rank" id={`rank-${rank}`}>
              {String(rank).padStart(2, '0')}
            </div>
          ) : (
            <div className="rank unranked">00</div>
          )
        ) : null}

        {user !== null ? (
          <>
            <Avatar size={32} user={user} />
            <span className="name">{user.name}</span>
          </>
        ) : (
          <span className="name">{getEvalTargetFormatted(title)}</span>
        )}
      </span>
      <span className="status-wrapper">
        {points !== 0 ? (
          <span className="score">
            {points} <span className="points">pts</span>
          </span>
        ) : (
          <span className="scoreless">—</span>
        )}
      </span>
    </div>
  );
}

export default LiveEvalScoreboard;
