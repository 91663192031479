import React from 'react';
import { ReviewableByItem } from '../../../types/types';

interface Props {
  heading: string;
  reviewableByItem: ReviewableByItem;
}

function RatingDetailsTable({ heading, reviewableByItem }: Props): JSX.Element {
  return (
    <>
      <h3>{heading}</h3>
      <section className="rating-table">
        <table className="nice-table">
          <thead>
            <tr>
              <th>Rating</th>
              <th>Score</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {reviewableByItem.ratings.map((ratingItem) => {
              const { rating, reviewRating } = ratingItem;
              return (
                <tr key={`rating-${rating.ratingId}`}>
                  <td>{rating.name}</td>
                  {reviewRating !== null ? (
                    <>
                      <td className="score">
                        <span>
                          {reviewRating.score}/{rating.ratingLevels[rating.ratingLevels.length - 1].score}
                        </span>
                      </td>
                      <td>{rating.ratingLevels[reviewRating.score - 1].levelDescription}</td>
                    </>
                  ) : (
                    <>
                      <td>N/A</td>
                      <td className="incomplete-action-txt">
                        {reviewableByItem.user.name} has not yet provided a rating
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </section>
    </>
  );
}

export default RatingDetailsTable;
