import React from 'react';
import HelpTag from '../../core/display/HelpTag';
import Form from '../../core/input/Form/Form';

interface Props {
  peerEvaluationOnly: boolean;
  setPeerEvaluationOnly: React.Dispatch<React.SetStateAction<boolean>>;
}

function TypeSetup({ peerEvaluationOnly, setPeerEvaluationOnly }: Props): JSX.Element {
  return (
    <>
      <Form.Header>
        <Form.Title>
          Assignment Type
          <HelpTag type="modal" heading="Assignment Type" margin="0 1rem" emphasize>
            <p>
              <b>Peer Assessment</b> assignments require participants to upload a submission (work product) that will be
              reviewed or assessed by their peers and/or instructors. When creating a group peer assessment, peer
              evaluation can be added.
            </p>
            <p>
              <b>Team Member Evaluation Only</b> assignments require individuals to evaluate their peers&apos; or other
              individual&apos;s contributions to a group project or class activity. Individuals do not upload a
              submission (work product) for these assignments.
            </p>
          </HelpTag>
        </Form.Title>
      </Form.Header>
      <Form.Body>
        <fieldset className="col-inputs">
          <legend>Choose an assignment type:</legend>
          <div className="rad-radio-btn">
            <input
              id="peerAssessment"
              type="radio"
              name="peerEvaluationOnly"
              value="false"
              checked={!peerEvaluationOnly}
              onChange={(e) => setPeerEvaluationOnly(e.target.value === 'true')}
            />
            <label htmlFor="peerAssessment">Peer Assessment</label>
          </div>
          <div className="rad-radio-btn">
            <input
              id="tmeOnly"
              type="radio"
              name="peerEvaluationOnly"
              value="true"
              checked={peerEvaluationOnly}
              onChange={(e) => setPeerEvaluationOnly(e.target.value === 'true')}
            />
            <label htmlFor="tmeOnly">Team Member Evaluation Only</label>
          </div>
        </fieldset>
      </Form.Body>
    </>
  );
}

export default TypeSetup;
