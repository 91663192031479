import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DailyTaskEvents, User } from '../../types/types';
import { KEY_SELECTED_USER } from '../../utils/constants';
import { storageAvailable, timeDiff } from '../../utils/functions';
import { getUserDailyActivity } from '../../utils/requests';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import TabList from '../core/layout/TabList/TabList';
import ActivityGraph from '../dashboard/teacher/ActivityGraph';
import UserDetailsPerformanceTab from './UserDetailsPerformanceTab';

function UserDetailsPage(): JSX.Element {
  const { userId } = useParams() as { userId?: string };

  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    if (storageAvailable('sessionStorage'))
      setUser(JSON.parse(window.sessionStorage.getItem(KEY_SELECTED_USER) ?? `${null}`));
  }, []);

  if (user && user.userId === userId) {
    return (
      <div className="page" id="user-details-page">
        <h1 className="sr-only">User Details</h1>
        <div className="tabs-wrapper">
          <TabList
            label="Roster Menu"
            tabs={
              <>
                <TabList.Tab id="details" controls="details-tab">
                  Details
                </TabList.Tab>
                <TabList.Tab id="activity" controls="activity-tab">
                  Activity
                </TabList.Tab>
                <TabList.Tab id="performance" controls="performance-tab">
                  Performance
                </TabList.Tab>
              </>
            }
          >
            <TabList.TabPanel id="details-tab" labeledBy="details">
              <DetailsTab user={user} />
            </TabList.TabPanel>
            <TabList.TabPanel id="activity-tab" labeledBy="activity">
              <ActivityTab user={user} />
            </TabList.TabPanel>
            <TabList.TabPanel id="performance-tab" labeledBy="performance">
              <UserDetailsPerformanceTab user={user} />
            </TabList.TabPanel>
          </TabList>
        </div>
      </div>
    );
  }
  return <LoadingSpinner />;
}

export interface UserDetailsTabProps {
  user: User;
}

function DetailsTab({ user }: UserDetailsTabProps): JSX.Element {
  return (
    <div className="details-card panel-sm ">
      <table>
        <tr>
          <th>Last Active</th>
          <td>{user.lastLoginTime ? `${timeDiff(user.lastLoginTime ?? '')} ago` : 'Never'}</td>
        </tr>
        <tr>
          <th>Email</th>
          <td>
            <a href={`mailto:${user.email}`}>{user.email}</a>
          </td>
        </tr>
        <tr>
          <th>Enrolled Courses</th>
          <td>x</td>
        </tr>
        <tr>
          <th>Joined</th>
          <td>{moment(user.createdAt).format('MMM D, YYYY @ HH:mm')}</td>
        </tr>
      </table>
    </div>
  );
}

function ActivityTab({ user }: UserDetailsTabProps): JSX.Element {
  const [dailyTaskEvents, setDailyTaskEvents] = useState<DailyTaskEvents[] | null>(null);

  useEffect(() => {
    getUserDailyActivity(user.userId, setDailyTaskEvents);
  }, [user]);

  if (dailyTaskEvents)
    return (
      <div id="activity-card">
        <h2>Activity</h2>
        <ActivityGraph height={150} dailyTaskEvents={dailyTaskEvents} dataDisplay="TASKS" />
      </div>
    );
  return <LoadingSpinner />;
}

export default UserDetailsPage;
